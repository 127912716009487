import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Box, Button, ButtonGroup} from "@material-ui/core";
import ThumbUp from "@material-ui/icons/ThumbUp";
import axios from "axios";
import classNames from "classnames";
import LikeComponent from "./LikeComponent";
import {Message} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import MediaItemCommentComponent from "./MediaItemCommentComponent";
import {useSnackbar} from "notistack";
import {MediaContext} from "../Contexts/MediaContext";

const style = (theme) => ({
    section: {
        height: '100%'
    },
    comments: {
        height: '80%',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.text.secondary,
        borderRadius: '4px',
        overflowY: 'scroll'
    },
    textField: {
        width: '100%'
    },
    liked: {
        color: theme.palette.success.main,
        padding: '15px'
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '15px'
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function CommentSection(props) {
    const {user} = props;
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const {mediaItem} = useContext(MediaContext)

    const [likes, setLikes] = useState([]);
    const [photoLiked, setPhotoLiked] = useState(false);

    const [commentText, setCommentText] = useState("");
    const [uploadingComment, setUploadingComment] = useState(false);

    const scrollDiv = useRef(null);
    const commentBox = useRef(null);

    useLayoutEffect(() => {
        scrollToBottom();
    }, [scrollDiv, mediaItem]);

    const scrollToBottom = () => {
        scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight;
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        commentBox.current?.addEventListener('keydown', stopPropagation);
        return () => commentBox.current?.removeEventListener('keydown', stopPropagation);
    }, [commentBox]);

    useEffect(() => {
        setLikes(mediaItem.likes);

        if (mediaItem.likes.map((like) => like.id).includes(user.id)) {
            setPhotoLiked(true);
        } else {
            setPhotoLiked(false);
        }
    }, [mediaItem]);

    const likePhoto = () => {
        setPhotoLiked(!photoLiked);

        if (!photoLiked) {
            axios.post(`/api/media/${mediaItem.id}/like/`).then((res) => {
                mediaItem.likes.push(user)
                setLikes(mediaItem.likes.slice())
            });
        } else {
            axios.delete(`/api/media/${mediaItem.id}/like/`).then((res) => {
                mediaItem.likes.map((like) => like.id === user.id && mediaItem.likes.pop(like));
                setLikes(mediaItem.likes.slice())
            });
        }
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            putComment()
        }
    }

    const putComment = () => {
        if (!uploadingComment) {
            setUploadingComment(true);

            const data = {
                text: commentText
            }

            axios.put(`/api/media/${mediaItem.id}/comments/`, data).then((res) => {
                enqueueSnackbar(res.data.msg, {variant: "success"});
                setCommentText("")
                mediaItem.comments.push(res.data.comment);
                setTimeout(scrollToBottom, 100);
            }).catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error.response.data.msg, {variant: "error"});
                }
            }).finally(() => {
                setUploadingComment(false);
            })
        }
    }

    const buttonClasses = cx({
        [classes.liked]: photoLiked,
        [classes.button]: !photoLiked
    })

    return (
        <div className={classes.section}>
            <Box mb={2} className={classes.comments} ref={scrollDiv}>
                <MediaItemCommentComponent user={user}/>
            </Box>
            <Box mb={1} display="flex" alignItems="center">
                <Box flexGrow={1} mr={1}>
                    <TextField ref={commentBox} disabled={uploadingComment} value={commentText} inputProps={{ maxLength: 4096 }} onKeyPress={onKeyPress} onChange={(e) => setCommentText(e.target.value)} className={classes.textField} variant="outlined" label="Comment plaatsen"/>
                </Box>
                <ButtonGroup>
                    <Button onClick={putComment} className={classes.button}>
                        <Message/>
                    </Button>
                    <Button onClick={likePhoto} className={buttonClasses}>
                        <ThumbUp/>
                    </Button>
                </ButtonGroup>

            </Box>
            <LikeComponent likes={likes}/>
        </div>
    )
}

export default CommentSection;