import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import {useWebsocket} from "../Contexts/WebSocketProvider";

const style = (theme) => ({
    root: {
        position: "relative",
    },
    image: {
        filter: "blur(0)",
        WebkitFilter: "blur(0)",
        width: "100%",
        maxHeight: "75vh",
        objectFit: "contain",
        zIndex: 20
    },
    imageTransparant: {
        opacity: 0
    },
    imageBlur: {
        position: "absolute",
        width: "100%",
        maxHeight: "75vh",
        filter: "blur(8px) brightness(0.5)",
        WebkitFilter: "blur(8px) brightness(0.5)",
        objectFit: "contain"
    },
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

const MultiImageHolder = ({mediaItem, loading}) => {
    const classes = useStyles();

    const [image, setImage] = useState(new Image());
    const [loaded, setLoaded] = useState(false);

    const {ws} = useWebsocket();

    useEffect(() => {
        ws.addEventListener("message", onMessage);
        return () => ws.removeEventListener("message", onMessage);
    }, [ws]);

    const onMessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type && data.type === "mediaitem_changed" && data.redownload && data.media_item && data.media_item.id === mediaItem.id) {
            const img = new Image();
            img.src = data.media_item.medium_view.file + `&${new Date().getTime()}`;
            setImage(img);
        }
    }

    useEffect(() => {
        const img = new Image();
        img.onload = onImageLoad;
        if (mediaItem.medium_view) {
            img.src = mediaItem.medium_view.file
        }
        setImage(img);
    }, [mediaItem]);

    const onImageLoad = () => {
        setLoaded(true);
    }

    return (
        <div className={classes.root}>
            <img className={classes.imageBlur} src={image.src}/>
            <img src={image.src} className={cx({[classes.image]: true, [classes.imageTransparant]: loading})}/>
        </div>
    );
};

export default MultiImageHolder;