import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {Box, Tooltip, Typography, useMediaQuery} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import SocialBar from "./SocialBar";
import DeletePhotoDialog from "../Dialogs/DeletePhotoDialog";
import useTheme from "@material-ui/core/styles/useTheme";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import {MediaContext} from "../Contexts/MediaContext";
import {MediaItem} from "../models/Types";
import {AspectRatio, BlurOn, DeleteForever, Link, PermMedia, Rotate90DegreesCcw} from "@material-ui/icons";
import axios from "axios";
import {useSnackbar} from "notistack";
import ItemReportDialog from "../Dialogs/ItemReportDialog";
import ItemHardlinkDialog from "../Dialogs/ItemHardlinkDialog";
import MediaItemCheckComponent from "./MediaItemChecksComponent";
import MultiImageHolder from "./MultiImageHolder";
import {useWebsocket} from "../Contexts/WebSocketProvider";
import EditImagePreview from "./EditImagePreview";

const dialogZIndex = 1000;

const style = (theme) => ({
    dialog: {
        overflow: "hidden",
        zIndex: `${dialogZIndex} !important`
    },
    social: {
        width: '100%',
        height: '100%'
    },
    dialogPaper: {
        minHeight: "80vh"
    },
    loadCircle: {
        position: 'absolute',
        left: 'auto',
        right: 'auto',
        zIndex: dialogZIndex + 3
    },
    image: {
        width: "100%",
        maxHeight: "75vh",
        objectFit: "contain",
        zIndex: dialogZIndex + 2
    },
    imageBlur: {
        position: "absolute",
        transform: "scale(1.0)",
        marginTop: theme.spacing(1),
        width: "100%",
        maxHeight: "75vh",
        filter: "blur(8px) brightness(0.3)",
        WebkitFilter: "blur(8px) brightness(0.3)",
        zIndex: dialogZIndex + 1,
        objectFit: "contain"
    },
    imagePaper: {
        position: "relative",
        padding: 10,
        maxHeight: '80vh'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        zIndex: dialogZIndex + 4
    },
    fab: {
        position: 'absolute',
        '&.MuiFab-primary': {
            bottom: theme.spacing(4),
            right: theme.spacing(4)
        }
    },
    back: {
        display: 'block',
        position: 'absolute',
        left: 0,
        height: 'auto',
        top: 100,
        bottom: 100,
        paddingLeft: '20px',
        paddingRight: '30px',
        cursor: 'pointer',
        zIndex: dialogZIndex + 3,
        borderRadius: '5px',
        '&:hover': {
            boxShadow: '50px 50px 113px #00000011 inset,-50px -50px 110px #00000011 inset',
        }
    },
    forward: {
        display: 'block',
        position: 'absolute',
        right: 0,
        height: 'auto',
        top: 100,
        bottom: 100,
        paddingLeft: '20px',
        paddingRight: '30px',
        cursor: 'pointer',
        zIndex: dialogZIndex + 3,
        borderRadius: '5px',
        '&:hover': {
            boxShadow: '50px 50px 113px #00000011 inset,-50px -50px 110px #00000011 inset',
        }
    },
    arrow: {
        position: 'relative',
        top: '50%',
        cursor: 'pointer',
        zIndex: dialogZIndex + 3
    },
    socialPart: {
        marginTop: theme.spacing(3)
    },
    reportText: {
        fontSize: '0.7rem',
        color: theme.palette.text.hint,
        cursor: "pointer",

        '&:hover': {
            color: theme.palette.text.primary,
            textDecoration: "underline"
        }
    },
    flippedIcon: {
        transform: "scaleX(-1)"
    },
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function MediaViewDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const {mediaItem} = useContext(MediaContext);

    const {user, album, setAlbum, previous, next, hasPrevious, hasNext, open, handleClose, albumLoading} = props;

    const [ photoUpdating, setPhotoUpdating ] = useState(false);
    const [ photoReportDialogOpen, setPhotoReportDialogOpen ] = useState(false);
    const [ deletePhotoDialogOpen, setDeletePhotoDialogOpen ] = useState(false);
    const [ hardlinkDialogOpen, setHardlinkDialogOpen ] = useState(false);

    const [editButtonsDisabled, setEditButtonsDisabled] = useState(false);

    const [ previewImageContent, setPreviewImageContent ] = useState(null);

    const {ws} = useWebsocket();

    const onDeleteMediaItem = () => {
        const media_items = album.media_items;
        const i = media_items.indexOf(mediaItem);
        if (i > -1) {
            media_items.splice(i, 1);
        }

        if (media_items.length === 0) {
            handleClose();
        }

        if (media_items.length === i) {
            previous();
        }

        setAlbum({...album})
    }

    const setAsThumbnail = () => {
        axios.post(`/api/album/${album.id}/thumbnail/`, {media_item: mediaItem.id}).then((result) => {
            enqueueSnackbar(result.data.msg, {variant: "success"});
        }).catch((error) => {
            if (error.response && error.response.data.msg) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            } else {
                enqueueSnackbar("Instellen als thumbnail mislukt", {variant: "error"});
            }
        });
    }

    useEffect(() => {
        ws.addEventListener("message", onMessage);
        return () => ws.removeEventListener("message", onMessage);
    }, [ws]);

    const onMessage = (event) => {
        const data = JSON.parse(event.data);

        if ((!!data.success || data.variant !== 'info') || (data.type && data.type === "mediaitem_changed")) {
            setEditButtonsDisabled(false);
        }

        if (data.type && data.type === "image_preview") {
            setEditButtonsDisabled(false);
            setPreviewImageContent(data.image);
        }
    }

    const requestPhotoBlur = () => {
        ws.send(JSON.stringify({
            media_item_id: mediaItem.id,
            command: 'blur_mediaitem_faces_request'
        }));

        setEditButtonsDisabled(true);
    }

    const rotatePhotoLeft = () => {
        ws.send(JSON.stringify({
            media_item_id: mediaItem.id,
            command: 'rotate_mediaitem_left'
        }));

        setEditButtonsDisabled(true);
    }

    const rotatePhotoRight = () => {
        ws.send(JSON.stringify({
            media_item_id: mediaItem.id,
            command: 'rotate_mediaitem_right'
        }));

        setEditButtonsDisabled(true);
    }

    const acceptPhotoBlur = () => {
        ws.send(JSON.stringify({
            media_item_id: mediaItem.id,
            command: 'blur_mediaitem_faces'
        }));

        setPreviewImageContent(null);
        setEditButtonsDisabled(true);
    }

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={open} fullWidth={true} maxWidth="xl" classes={{root: classes.dialog, paper: classes.dialogPaper}} onClose={handleClose}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={8}>
                            <div className={classes.imagePaper}>
                                <Box display="flex" justifyContent="center" alignItems="center" pt={1}>
                                    {hasNext &&
                                        <Box className={classes.forward} onClick={next}>
                                            <ArrowForward className={classes.arrow}/>
                                        </Box>
                                    }
                                    {hasPrevious &&
                                        <Box className={classes.back} onClick={previous}>
                                            <ArrowBack className={classes.arrow}/>
                                        </Box>
                                    }

                                    {(albumLoading || photoUpdating) &&
                                        <CircularProgress className={classes.loadCircle}/>
                                    }

                                    {(mediaItem.medium_view && mediaItem.type === MediaItem.IMAGE) ?
                                        <MultiImageHolder key={mediaItem.id} mediaItem={mediaItem} loading={albumLoading || photoUpdating}/>
                                        : (mediaItem.full_size && mediaItem.type === MediaItem.VIDEO) &&
                                        [
                                            <video key={mediaItem.id} className={classes.image} controls>
                                                <source src={mediaItem.full_size.file} type="video/mp4"/>
                                            </video>
                                        ]
                                    }
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={4} className={classes.socialPart}>
                            <Box display="flex">
                                <Hidden mdDown>
                                    <Box ml={1}>
                                        <Divider orientation="vertical"/>
                                    </Box>
                                </Hidden>


                                <Box ml={2} className={classes.social}>
                                    <Box display="flex" alignItems="center">
                                        {user.photo_delete_permission &&
                                            <Box ml={1}>
                                                <Tooltip title={mediaItem.type === MediaItem.IMAGE ? "Foto verwijderen" : "Video verwijderen"}>
                                                    <IconButton onClick={() => setDeletePhotoDialogOpen(true)}>
                                                        <DeleteForever />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                        {(user.photo_view_permission && mediaItem.type === MediaItem.IMAGE) &&
                                            <Box ml={1}>
                                                <Tooltip title="Volle resolutie bekijken">
                                                    <IconButton onClick={() => window.open(mediaItem.full_size.file)}>
                                                        <AspectRatio />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                        {user.album_change_permission &&
                                            <Box ml={1}>
                                                <Tooltip title={`${mediaItem.type === MediaItem.IMAGE ? "Foto" : (mediaItem.type === MediaItem.VIDEO ? "Video" : "")} instellen als thumbnail`}>
                                                    <IconButton onClick={setAsThumbnail}>
                                                        <PermMedia/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                        {user.mediaitemhardlink_add_permission &&
                                            <Box ml={1}>
                                                <Tooltip title={`Hardlink naar deze ${mediaItem.type === MediaItem.IMAGE ? "foto" : (mediaItem.type === MediaItem.VIDEO ? "video" : "")} maken`}>
                                                    <IconButton onClick={() => setHardlinkDialogOpen(true)}>
                                                        <Link />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                        {(user.photo_change_permission && mediaItem.type === MediaItem.IMAGE) &&
                                            <Box display="flex" alignItems="center">
                                                <Box ml={1}>
                                                    <Tooltip title={`Foto linksom draaien`}>
                                                        <IconButton disabled={editButtonsDisabled} onClick={rotatePhotoLeft}>
                                                            <Rotate90DegreesCcw />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Box ml={1}>
                                                    <Tooltip title={`Foto rechtsom draaien`}>
                                                        <IconButton disabled={editButtonsDisabled} onClick={rotatePhotoRight}>
                                                            <Rotate90DegreesCcw className={classes.flippedIcon}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Box ml={1}>
                                                    <Tooltip title={`Gezichten automatisch blurren`}>
                                                        <IconButton disabled={editButtonsDisabled} onClick={requestPhotoBlur}>
                                                            <BlurOn />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                {editButtonsDisabled &&
                                                    <CircularProgress variant="indeterminate" size={20}/>
                                                }
                                            </Box>
                                        }
                                    </Box>

                                    <SocialBar user={user} photo={mediaItem}/>
                                    <Box mt={2} mb={1} display="flex">
                                        <Box flexGrow={1}>
                                            {user.mediaitemcheck_add_permission &&
                                                <MediaItemCheckComponent next={next}/>
                                            }
                                        </Box>

                                        <Typography className={classes.reportText} variant="subtitle1" component="p" onClick={() => setPhotoReportDialogOpen(true)}>
                                            {mediaItem.type === MediaItem.IMAGE ? "Foto": "Video"} rapporteren
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <ItemReportDialog open={photoReportDialogOpen} onClose={() => setPhotoReportDialogOpen(false)} onDeletePhoto={onDeleteMediaItem}/>
            <ItemHardlinkDialog open={hardlinkDialogOpen} onClose={() => setHardlinkDialogOpen(false)}/>
            <DeletePhotoDialog photo={mediaItem} open={deletePhotoDialogOpen} onClose={() => setDeletePhotoDialogOpen(false)} onDeletePhoto={onDeleteMediaItem} />

            <EditImagePreview previewImageContent={previewImageContent} onClose={() => setPreviewImageContent(null)} onAccept={acceptPhotoBlur}/>
        </div>
    )
}

export default MediaViewDialog;