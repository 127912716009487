import React, {useCallback, useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Tooltip, Typography} from "@material-ui/core";
import {Block, Check} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {MediaContext} from "../Contexts/MediaContext";
import {CheckTypes, MediaItem} from "../models/Types";
import {useSnackbar} from "notistack";
import axios from "axios";
import userToString from "../Utils/Utils";
import dayjs from "dayjs";
import classNames from "classnames";

const style = (theme) => ({
    root: {
        height: 200
    },
    checkIcon: {
        color: theme.palette.success.main
    },
    blockIcon: {
        color: theme.palette.error.main
    },
    iconSmall: {
        fontSize: "0.8rem",
        marginRight: theme.spacing(1)
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

const MediaItemCheckComponent = ({check}) => {
    const classes = useStyles();

    const icon = check.check === CheckTypes.ACCEPTED ? <Check className={cx({[classes.checkIcon]: true, [classes.iconSmall]: true})}/> : <Block className={cx({[classes.blockIcon]: true, [classes.iconSmall]: true})}/>

    return (
        <Box display="flex" alignItems="center">
            {icon} {userToString(check.checked_by)} op {dayjs(check.created_on).format("YYYY-MM-DD [om] HH:mm")}
        </Box>
    )
}

const MediaItemChecksComponent = ({previous, next}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const {mediaItem} = useContext(MediaContext);
    const [checks, setChecks] = useState([]);

    const doRequest = useCallback((checkType) => {
        axios.put(`/api/media/${mediaItem.id}/check/`, {check_type: checkType}).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: res.data.variant, autoHideDuration: 500});
            const newCheck = res.data.data;
            setChecks([...checks.filter(check => check.checked_by.id !== newCheck.checked_by.id), newCheck]);
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: error.response.data.variant});
            }
        });
    }, [mediaItem, checks]);

    const acceptItem = () => {
        doRequest(CheckTypes.ACCEPTED);
        next();
    }

    const rejectItem = () => {
        doRequest(CheckTypes.NOT_ACCEPTED);
        next();
    }

    const keyListener = (e) => {
        if (e.key === "j") {
            acceptItem();
        } else if (e.key === "n") {
            rejectItem();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyListener);
        return () => {
            document.removeEventListener('keydown', keyListener);
        }
    }, [mediaItem, checks]);

    useEffect(() => {
        axios.get(`/api/media/${mediaItem.id}/check/`).then((res) => {
            setChecks(res.data);
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: error.response.data.variant});
            }
        });
    }, [mediaItem]);

    return (
        <div className={classes.root}>
            <Box display="flex">
                <Tooltip title={"J - Item goedkeuren"}>
                    <IconButton onClick={acceptItem}>
                        <Check className={classes.checkIcon}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"N - Item afkeuren"}>
                    <IconButton onClick={rejectItem}>
                        <Block className={classes.blockIcon}/>
                    </IconButton>
                </Tooltip>
            </Box>
            {checks.length > 0 &&
                <Typography variant="subtitle1">
                    {mediaItem.type === MediaItem.IMAGE ? "Foto": "Video"} gecheckt door:
                </Typography>
            }
            {checks.map((check, i) =>
                <MediaItemCheckComponent key={check.id} check={check}/>
            )}
        </div>
    );
};

export default MediaItemChecksComponent;