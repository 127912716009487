import React, {useEffect, useMemo, useState} from "react";
import {Route, useHistory, useLocation, withRouter, Switch, Redirect, NavLink} from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import {
    Collapse,
    Container,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    Zoom
} from "@material-ui/core";
import ShutterLogo from "./assets/img/frontend_src_assets_img_shutter-camera.svg";
import AlbumPage from "./Pages/AlbumPage";
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import HomePage from "./Pages/HomePage";
import {
    AccountBox,
    ArrowBack,
    Build,
    FormatListBulleted,
    Menu,
    OpenInNew,
    PictureAsPdf,
    SupervisorAccount
} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import MadeByComponent from "./Components/MadeByComponent";
import StateProvider from "./Contexts/StateProvider"
import PersonalPage from "./Pages/PersonalPage";
import Hidden from "@material-ui/core/Hidden";
import classNames from "classnames";
import {useUser} from "./Contexts/UserProvider";
import ReviewPage from "./Pages/ReviewPage";
import WebSocketProvider from "./Contexts/WebSocketProvider";

const style = (theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(4),
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.2em"
        }
    },
    appBar:{
        zIndex: 800
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff',
    },
    logo: {
        cursor: "pointer",
        margin: 1,
        [theme.breakpoints.down('xs')]: {
            maxWidth: "30px"
        }
    },
    appBanner: {
        position: "absolute",
        transition: "all 300ms ease-in-out",
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(5)
        }
    },
    shiftRight: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(10),
        },
        marginLeft: theme.spacing(7),
        transition: "all 300ms ease-in-out"
    },
    drawerContainer: {
        overflow: 'auto'
    },
    drawer: {
        width: 240,
        flexShrink: 0
    },
    drawerPaper: {
        width: 240
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10)
    },
    menuButton: {
        transition: "all 200ms ease-in-out",
        marginRight: theme.spacing(2),
        transform: "scale(1.0)",
        opacity: "1",
        [theme.breakpoints.up('md')]: {
            transform: "scale(0.0)",
            opacity: "0"
        }
    },
    backButton: {
        position: "absolute",
        transition: "all 300ms ease-in-out",
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(4),
        }
    },
    navButtons: {
        position: "absolute",
        right: theme.spacing(2)
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function Main() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});

    const {user, setUser} = useUser();

    const [bottom, setBottom] = useState(false);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const showBackButton = useMemo(() => location.pathname !== "/" && history.length > 0, [location, history]);

    const showAdminButton = () => {
        return (user !== null && user.is_staff);
    }

    const showManualDownloadButton = () => {
        return (user !== null && (user.album_add_permission || user.photo_add_permission))
    }

    const showMediaItemReportButton = () => (user !== null && user.mediaitemreport_change_permission);

    useEffect(() => {
        axios.get('/api/user/me/').then((res) => {
            setUser(res.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 403) {
                    window.location.href = `/api/login/?next=${window.location.pathname}`;
                } else {
                    setError(error.response);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });

        window.onscroll = checkBottomVisible;
        window.onresize = checkBottomVisible;
        setInterval(checkBottomVisible, 1000);
    }, []);

    const logout = () => {
        window.location.href = "/api/logout/";
    }

    const admin = () => {
        window.open("/admin");
    }

    const manual = () => {
        window.open("/static/handleiding.pdf");
    }

    const checkBottomVisible = () => {
        let bottom = false;
        if (window.innerHeight > document.body.offsetHeight) {
            bottom = true;
        } else if (window.innerHeight + window.pageYOffset === document.body.offsetHeight) {
            bottom = true;
        }

        setBottom(bottom)
    }

    const handleDrawerOpen = (open) => {
        setMobileOpen(open);
    };

    const navigateToRoot = () => location.pathname !== "/" && history.push("/");


    const drawer = ([
        <Toolbar key="toolbar" />,
        <div className={classes.drawerContainer} key="drawer">
            <List>
                <Hidden mdUp>
                    <ListItem button onClick={() => {handleDrawerOpen(false)}} component={NavLink} to='/personal'>
                        <ListItemIcon><AccountBox /></ListItemIcon>
                        <ListItemText primary={'Mijn fotoplatform'}/>
                    </ListItem>
                </Hidden>

                {showMediaItemReportButton() &&
                    <ListItem button onClick={() => handleDrawerOpen(false)} component={NavLink} to='/review'>
                        <ListItemIcon><FormatListBulleted /></ListItemIcon>
                        <ListItemText primary={'Gerapporteerde foto\'s reviewen'}/>
                    </ListItem>
                }

                {showManualDownloadButton() &&
                    <ListItem button onClick={() => {handleDrawerOpen(false); manual();}}>
                        <ListItemIcon><PictureAsPdf /></ListItemIcon>
                        <ListItemText primary={'Handleiding'}/>
                        <OpenInNew color="disabled" fontSize="small"/>
                    </ListItem>
                }

                {showAdminButton() &&
                    <ListItem button onClick={() => {handleDrawerOpen(false); admin();}}>
                        <ListItemIcon><Build /></ListItemIcon>
                        <ListItemText primary={'Admin'}/>
                        <OpenInNew color="disabled" fontSize="small"/>
                    </ListItem>
                }
            </List>
        </div>
    ])

    const appBar = (
        <AppBar className={classes.appBar} position="fixed">
            <Toolbar>

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => handleDrawerOpen(!mobileOpen)}
                    className={classes.menuButton}>
                    <Menu />
                </IconButton>

                <Box className={classes.backButton}>
                    <Zoom in={showBackButton}>
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBack/>
                        </IconButton>
                    </Zoom>
                </Box>


                <Box display="flex" alignItems="center" className={cx({[classes.appBanner]: true, [classes.shiftRight]: showBackButton})}>
                    <ShutterLogo className={classes.logo} width={40} height={40} onClick={navigateToRoot}/>

                    <Typography variant="h6" className={classes.title}>
                        DSB Fotoplatform
                    </Typography>
                </Box>

                <Box display="flex" className={classes.navButtons}>

                    <Hidden smDown>
                        {location.pathname !== "/personal" &&
                            <Button color="inherit" onClick={() => history.push('/personal')}>Mijn fotoplatform</Button>
                        }
                        {showMediaItemReportButton() &&
                            <Button color="inherit" onClick={() => history.push('/review')}>Review</Button>
                        }
                        {showAdminButton() &&
                            <Button color="inherit" onClick={admin}>Admin</Button>
                        }
                        {showManualDownloadButton() &&
                            <Button color="inherit" onClick={manual}>Handleiding</Button>
                        }
                    </Hidden>
                    <Button color="inherit" onClick={logout}>Logout</Button>
                </Box>
            </Toolbar>
        </AppBar>
    )

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {appBar}

            {!loading && (
                user ?
                    <div>
                        <Hidden smUp implementation="css">
                            <Drawer className={classes.drawer} variant="temporary" anchor={theme.direction === 'rtl' ? 'right' : 'left'} open={mobileOpen} onClose={() => {handleDrawerOpen(false)}} ModalProps={{keepMounted: true}}>
                                {drawer}
                            </Drawer>
                        </Hidden>

                        <main>
                            <Container className={classes.container}>
                                <WebSocketProvider>
                                    <StateProvider>
                                        <Switch>
                                            <Route exact path="/" render={(props) => <HomePage onLoad={checkBottomVisible}/>}/>
                                            <Route exact path="/review/" render={(props) => <ReviewPage onLoad={checkBottomVisible}/>}/>
                                            <Route exact path="/personal/" render={(props) => <PersonalPage onLoad={checkBottomVisible}/>}/>
                                            <Route exact path="/album/:id/" render={(props) => <AlbumPage onLoad={checkBottomVisible}/>}/>
                                            <Route render={() => <Redirect to="/"/>} />
                                        </Switch>
                                    </StateProvider>
                                </WebSocketProvider>
                            </Container>
                            <MadeByComponent onBottom={bottom}/>
                        </main>
                    </div>
                :
                <Container className={classes.container}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="h3" component="h3">
                            Error: {error.status}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box display="flex" justifyContent="center" mt={2} mb={4}>
                        <Typography variant="h4" color="textSecondary" component="h4">
                            Er ging iets fout bij het ophalen van alle albums.
                        </Typography>
                    </Box>
                </Container>
            )}
        </div>

    )
}

export default withRouter(Main);