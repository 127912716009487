import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import {CardActionArea, CardContent, CircularProgress, Tooltip} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import ImageIcon from "@material-ui/icons/Image";
import MovieIcon from "@material-ui/icons/Movie";
import {MediaItem} from "../models/Types";
import {DoneAll} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    media: {
        height: 140,
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    actionArea: {
        height: 140
    },
    icon: {
        position: "absolute",
        right: "5px",
        top: "5px",
        fill: "#ffffff",
        fillOpacity: 0.3
    },
    verified: {
        position: "absolute",
        left: "5px",
        top: "5px",
        fill: theme.palette.primary.main
    },
    progress: {
        position: "absolute",
        color: theme.palette.text.disabled,
        zIndex: 1,
        top: "35%",
        left: "40%"
    }
}));

function MediaCard(props) {
    const classes = useStyles();
    const history = useHistory();

    const [loaded, setLoaded] = useState(false);
    const [image, setImage] = useState(null);

    const { mediaItem } = props;

    useEffect(() => {
        const img = new Image();
        img.onload = onImageLoad;
        if (mediaItem.thumbnail) {
            img.src = mediaItem.thumbnail.file
        }
        setImage(img);
    }, [mediaItem]);

    const onImageLoad = () => {
        setLoaded(true);
    }

    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.actionArea}>
                {mediaItem.verified &&
                    <Tooltip title={"Verified"}>
                        <DoneAll className={classes.verified}/>
                    </Tooltip>
                }
                {(mediaItem.type === MediaItem.IMAGE) &&
                    <ImageIcon className={classes.icon}/>
                }
                {(mediaItem.type === MediaItem.VIDEO) &&
                    <MovieIcon className={classes.icon}/>
                }
                {mediaItem.thumbnail ? (
                    <>
                        {loaded ? <div className={classes.media} style={{backgroundImage: `url(${image.src})`}}/> : <CircularProgress className={classes.progress}/>}
                    </>
                    ) :
                    <CardContent/>
                }
            </CardActionArea>
        </Card>
    )
}

export default MediaCard;