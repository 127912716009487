import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import AlbumCard from "./AlbumCard";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import axios from 'axios';
import Skeleton from "@material-ui/lab/Skeleton";
import {
    CircularProgress,
    Divider,
    GridList,
    GridListTile,
    InputLabel, isWidthUp,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {useSnackbar} from "notistack";
import PageSelector from "./PageSelector";
import {AlbumsContext} from "../Contexts/AlbumsContext";
import {SearchContext} from "../Contexts/SearchContext";
import {SortTypes} from "../models/Types";
import {SortContext} from "../Contexts/SortContext";

const useStyles = makeStyles((theme) => ({
    card: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    title: {
        fontSize: "3em",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2em"
        }
    },
    subtitle: {
        fontSize: "2em",
        [theme.breakpoints.down('xs')]: {
            fontSize: "1em"
        }
    },
    searchField: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: "45vw"
        }
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}));

function AlbumView(props) {
    const classes = useStyles();

    const {id, user, name, queryString, sortEnabled, skeletonRows, parentReload, setParentReload, availableTags, availableGroups, onLoad, oneLine, hideEmpty, showLoading} = props;
    const { enqueueSnackbar } = useSnackbar();

    const {albumSet, setAlbumSet} = useContext(AlbumsContext);
    const {search, startSearch, setStartSearch} = useContext(SearchContext);
    const {sortType, setSortType} = useContext(SortContext);

    const firstUpdate = useRef(true);

    const [loading, setLoading] = useState(false);

    const pages = useMemo(() => albumSet[id] ? albumSet[id].pages : 1, [albumSet, id]);
    const selectedPage = useMemo(() => albumSet[id] ? albumSet[id].selectedPage : 1, [albumSet, id]);
    const albums = useMemo(() => albumSet[id] ? albumSet[id].albums[selectedPage] ? albumSet[id].albums[selectedPage] : null : null, [albumSet, id, selectedPage]);

    const query = useMemo(() => queryString ? `&${queryString}` : "", [queryString]);
    const searchTimeout = useRef(null);

    const reload = useCallback(() => {
        reloadAlbum();
    }, [albums])

    useEffect(() => {
        if (albums === null) {
            reload();
        }
    }, [albums]);

    useEffect(() => {
        if (parentReload) {
            reloadAlbum().then(() => {
                setParentReload(false);
            })
        }
    }, [parentReload]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        resetAlbumCache();
    }, [sortType]);

    const resetAlbumCache = () => {
        Object.keys(albumSet[id].albums).map((key, i) => {
            delete albumSet[id].albums[key];
        });
        albumSet[id].selectedPage = 1;
        setAlbumSet({...albumSet});
    }

    useEffect(() => {
        clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => {
            if (startSearch) {
                resetAlbumCache();
                setStartSearch(false);
            }
        }, 1000);

        return () => clearTimeout(searchTimeout.current);
    }, [search]);

    const reloadAlbum = () => {
        setLoading(true);
        return axios.get(`/api/album/?page=${selectedPage}&search=${search}${query}&sort_type=${sortType}`).then((res) => {
            albumSet[id] = {
                albums: albumSet[id] ? albumSet[id].albums : {},
                pages: res.data.total_pages,
                selectedPage: selectedPage
            };

            albumSet[id].albums[selectedPage] = res.data.albums;

            setAlbumSet({...albumSet});
            onLoad();
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            if (err.response) {
                if (err.response.status === 400) {
                    enqueueSnackbar("Er ging iets fout bij het ophalen van dit album.", {variant: "error"})
                }
            }
        })
    }

    const setAlbum = (i, album) => {
        albumSet[id].albums[selectedPage][i] = album;
        setAlbumSet({...albumSet});
    }

    const nextPage = () => {
        if (selectedPage < pages) {
            albumSet[id].selectedPage = selectedPage + 1;
            setAlbumSet({...albumSet});
        }
    }

    const previousPage = () => {
        if (selectedPage > 1) {
            albumSet[id].selectedPage = selectedPage - 1;
            setAlbumSet({...albumSet});
        }
    }

    const setPage = (page) => {
        if (page >= 1 && page <= pages) {
            albumSet[id].selectedPage = page;
            setAlbumSet({...albumSet});
        }
    }

    document.onkeydown = (e) => {
        if (e.key === "ArrowLeft") {
            previousPage();
        } else if (e.key === "ArrowRight") {
            nextPage();
        }
    }

    const changeSortType = (e) => {
        setSortType(e.target.value);
    }

    const getGridListCols = () => {
        if (isWidthUp('xl', props.width)) {
            return 4;
        }

        if (isWidthUp('lg', props.width)) {
            return 3;
        }

        if (isWidthUp('md', props.width)) {
            return 2;
        }

        return 1;
    }

    if (!albums) {
        if (showLoading) {
            return (
                <div>
                    <Box display="flex">
                        <Typography variant="h3" component="h3">
                            {name}
                        </Typography>
                    </Box>

                    <Divider/>
                    <Box mt={2} mb={4}>
                        <Grid container spacing={1}>
                            {
                                [...Array(4 * skeletonRows)].map((e, i) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                            <Box mb={18}>
                                                <Box mb={3}>
                                                    <Skeleton variant="rect" height={140}/>
                                                    <Skeleton variant="text"/>
                                                    <Skeleton variant="text" width={80}/>
                                                </Box>
                                                <Box display="flex">
                                                    <Box mr={1}>
                                                        <Skeleton variant="text" width={80}/>
                                                    </Box>
                                                    <Box mr={1}>
                                                        <Skeleton variant="text" width={80}/>
                                                    </Box>
                                                    <Box mr={1}>
                                                        <Skeleton variant="text" width={80}/>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </div>
            )
        } else {
            return null;
        }
    }

    if (!albums && hideEmpty) {
        return null;
    }

    if (albums.length === 0) {
        if (!hideEmpty) {
            return (
                <div>
                    <Box display="flex" alignItems="center">
                        <Typography className={classes.title} variant="h3" component="h3">
                            {name}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box display="flex" justifyContent="center" mt={2} mb={4}>
                        <Typography className={classes.subtitle} variant="h4" color="textSecondary" component="h4">
                            Geen albums gevonden in deze categorie
                        </Typography>
                    </Box>
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <div>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <Typography xs={4} className={classes.title} variant="h3" component="h3">
                        {name}
                    </Typography>
                </Box>
                {loading &&
                    <CircularProgress size={20}/>
                }
                {sortEnabled &&
                <Box ml={2} display="flex" alignItems="center">
                    <Box mr={1}>
                        <InputLabel id="select-label">Sorteren op:</InputLabel>
                    </Box>
                    <Select
                        labelId={"select-label"}
                        value={sortType}
                        onChange={changeSortType}
                    >
                        <MenuItem value={SortTypes.NEWEST_DESC}>Aanmaken: oudste eerst</MenuItem>
                        <MenuItem value={SortTypes.NEWEST_ASC}>Aanmaken: nieuwste eerst</MenuItem>
                        <MenuItem value={SortTypes.DATE_DESC}>Datum: oudste eerst</MenuItem>
                        <MenuItem value={SortTypes.DATE_ASC}>Datum: nieuwste eerst</MenuItem>
                        <MenuItem value={SortTypes.MOST_LIKED_ALBUM}>Likes: album met meeste likes</MenuItem>
                        <MenuItem value={SortTypes.MOST_LIKED_PHOTO}>Likes: foto's met meeste likes</MenuItem>
                        <MenuItem value={SortTypes.LAST_UPDATED_ALBUM}>Meest recent bijgewerkt</MenuItem>
                    </Select>
                </Box>
                }

            </Box>
            <Divider/>
            <Box mt={2} mb={4}>
                {oneLine ?
                    <GridList className={classes.gridList} cellHeight={"auto"} cols={getGridListCols} spacing={8}>
                        {albums.map((album, i) => {
                            return (
                                <GridListTile key={album.id}>
                                    <AlbumCard triggerReload={reload} showFeaturedButton={true} user={user} album={album} setAlbum={(album) => setAlbum(i, album)} className={classes.card} availableGroups={availableGroups} availableTags={availableTags}/>
                                </GridListTile>
                            )
                        })}
                    </GridList> :
                    <Grid container spacing={1}>
                        {
                            albums.map((album, i) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={album.id}>
                                        <AlbumCard triggerReload={reload} showFeaturedButton={true} user={user} album={album} setAlbum={(album) => setAlbum(i, album)} className={classes.card} availableGroups={availableGroups} availableTags={availableTags}/>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                }

                <PageSelector nextPage={nextPage} previousPage={previousPage} setPage={setPage} selectedPage={selectedPage} pages={pages} pagesVisible={6}/>
            </Box>
        </div>
    )
}

export default AlbumView;