import React, {createContext, useContext, useState} from 'react';

export const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

function UserProvider({children}) {
    const [user, setUser] = useState(null);

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;