import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from "axios";

export const SimpleDataContext = createContext(null);

export const useGroups = () => useContext(SimpleDataContext).availableGroups;
export const useTags = () => useContext(SimpleDataContext).availableTags;
export const useAlbumList = () => useContext(SimpleDataContext).availableAlbums;
export const useConfig = () => useContext(SimpleDataContext).config;

function SimpleDataProvider({children}) {
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [availableAlbums, setAvailableAlbums] = useState([]);
    const [config, setConfig] = useState({});

    useEffect(() => {
        axios.get('/api/album/tags/').then((res) => {
            setAvailableTags(res.data);
        });
        axios.get('/api/group/').then((res) => {
            setAvailableGroups(res.data);
        });
        axios.get('/api/albumsimple/').then((res) => {
            setAvailableAlbums(res.data);
        });
        axios.get('/api/config/').then((res) => {
            setConfig(res.data);
        });
    }, []);

    return (
        <SimpleDataContext.Provider value={{availableTags: {availableTags, setAvailableTags}, availableGroups: {availableGroups, setAvailableGroups}, availableAlbums: {availableAlbums, setAvailableAlbums}, config: {config, setConfig}}}>
            {children}
        </SimpleDataContext.Provider>
    )
}

export default SimpleDataProvider;