import React, {useState} from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Cancel, Check} from "@material-ui/icons";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";

const style = (theme) => ({
    lightBox: {
        zIndex: 1200
    },
    checkButton: {
        marginRight: theme.spacing(4),
        color: theme.palette.success.main
    },
    cancelButton: {
        marginRight: theme.spacing(4),
        color: theme.palette.error.main
    },
    explanationText: {
        color: 'white',
        marginRight: theme.spacing(1),
        fontWeight: "bold"
    },
    dialog: {
        zIndex: 1800
    }
});

const useStyles = makeStyles(style);

const EditImagePreview = ({previewImageContent, onClose, onAccept}) => {
    const classes = useStyles();

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const accept = () => {
        setShowConfirmationDialog(true);
    }

    const acceptConfirm = () => {
        setShowConfirmationDialog(false);
        onAccept();
    }

    const close = () => {
        onClose();
    }

    return (
        <Backdrop className={classes.lightBox} open={previewImageContent !== null}>
            <Box style={{height: '80%'}}>
                <Box mb={1} display="flex" style={{height: '100%'}}>
                    <img height="100%" src={`data:image/jpg;base64,${previewImageContent}`}/>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography className={classes.explanationText}>
                        Wijzigingen accepteren
                    </Typography>

                    <IconButton edge="end" aria-label="comments" className={classes.checkButton} onClick={accept}>
                        <Check fontSize="large"/>
                    </IconButton>

                    <Typography className={classes.explanationText}>
                        Wijzigingen afkeuren
                    </Typography>
                    <IconButton edge="end" aria-label="comments" className={classes.cancelButton} onClick={close}>
                        <Cancel fontSize="large"/>
                    </IconButton>
                </Box>
            </Box>
            <Dialog open={showConfirmationDialog} className={classes.dialog}>
                <DialogTitle>
                    Foto permanent wijzigen?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Als je deze foto wijzigt zal deze permanent aangepast worden, het blurren van gezichten kan niet ongedaan gemaakt worden.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowConfirmationDialog(false)} color="primary">
                        Annuleren
                    </Button>
                    <Button onClick={acceptConfirm} color="primary" autoFocus>
                        Wijzig de foto!
                    </Button>
                </DialogActions>
            </Dialog>
        </Backdrop>
    );
};

export default EditImagePreview;