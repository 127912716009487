import React, {useEffect, useMemo, useState} from "react";
import Card from "@material-ui/core/Card";
import {Box, CardActionArea, CardContent, Typography} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import {CloudDownload, Delete, Edit, Explore, ExploreOff, Star, StarBorder} from "@material-ui/icons";
import classNames from "classnames";
import DeleteAlbumDialog from "../Dialogs/DeleteAlbumDialog";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import {useSnackbar} from "notistack";
import AlbumEditDialog from "../Dialogs/AlbumEditDialog";
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadAlbumDialog from "../Dialogs/DownloadAlbumDialog";
import userToString, {getDynamicFontSize} from "../Utils/Utils";
import {getInitialUploader} from "../models/InitialUploaders";

const style = (theme) => ({
    card: {
        width: '100%',
        maxWidth: '300px',
        animation: `$fadeIn 300ms ${theme.transitions.easing.easeInOut}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0
        },
        "100%": {
            opacity: 1
        }
    },
    media: {
        height: 140,
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover"
    },
    mediaNotPublished: {
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)"
    },
    progress: {
        position: "absolute",
        color: theme.palette.text.disabled,
        zIndex: 1,
        top: "15%",
        left: "40%"
    },
    favorite: {
        '& path': {
            fill: theme.palette.warning.main
        }
    },
    notPublished: {
        backgroundColor: theme.palette.action.disabledBackground
    },
    albumTitle: {
        fontStyle: "bold",
    },
    createdByText: {
        fontStyle: 'italic'
    },
    chip: {
        margin: theme.spacing(0.5)
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function AlbumCard(props) {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { user, album, setAlbum, triggerReload, showFeaturedButton } = props;

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

    const [imageLoaded, setImageLoaded] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);

    const buttonsVisible = useMemo(() => user.album_change_permission || user.album_download_permission || user.album_delete_permission, [user]);

    useEffect(() => {
        const img = new Image();
        img.onload = () => setImageLoaded(true);
        img.src = album.thumbnail
        setThumbnail(img);
    }, [album]);

    const reloadAlbum = () => {
        return axios.get(`/api/album/${album.id}/`).then((res) => {
            setAlbum(res.data);
        });
    }

    const updateAlbum = (newAlbum) => {
        const data = {
            title: newAlbum.title,
            description: newAlbum.description,
            long_description: newAlbum.long_description,
            featured: newAlbum.featured,
            published: newAlbum.published,
            album_date: newAlbum.album_date,
            tags: newAlbum.tags.map(tag => tag.name),
            viewable_by: newAlbum.viewable_by.map(group => group.name),
            parent_album: newAlbum.parent_album
        }

        return axios.post(`/api/album/${newAlbum.id}/`, data).then((res) => {});
    }

    const setFeatured = (featured) => {
        const newAlbum = {...album};
        newAlbum.featured = featured;
        setAlbum(newAlbum);
        updateAlbum(newAlbum).then(() => {
            enqueueSnackbar(featured? "Album is nu gefeatured" : "Album is niet meer gefeatured", {variant: "success"})
        }).catch((err) => {
            if (err.response) {
                enqueueSnackbar(err.response.data.msg, {variant: "error"});
            } else {
                enqueueSnackbar("Something went wrong", {variant: "error"});
            }
        });
    }

    const setPublished = (published) => {
        const newAlbum = {...album};
        newAlbum.published = published;
        setAlbum(newAlbum);
        updateAlbum(newAlbum).then(() => {
            enqueueSnackbar(published? "Album is nu gepubliceerd" : "Album is niet meer gepubliceerd", {variant: "success"})
        }).catch((err) => {
            if (err.response) {
                enqueueSnackbar(err.response.data.msg, {variant: "error"});
            } else {
                enqueueSnackbar("Something went wrong", {variant: "error"});
            }
        });
    }

    const openEditDialog = () => {
        setEditDialogOpen(true);
    }

    const closeEditDialog = () => {
        setEditDialogOpen(false);
    }

    const openDeleteDialog = () => {
        setDeleteDialogOpen(true);
    }

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false)
        triggerReload();
    }

    const openDownloadDialog = () => {
        setDownloadDialogOpen(true);
    }

    const closeDownloadDialog = () => {
        setDownloadDialogOpen(false);
    }

    const navigateToAlbum = () => {
        history.push(`/album/${album.id}/`);
    }

    const mouseDownHandler = (e) => {
        if (e.button === 1) {
            window.open(`/album/${album.id}/`);
        }
    }

    const cardClass = cx({
        [classes.card]: true,
        [classes.notPublished]: album && !album.published
    })

    if (!album) {
        return (
            <Card className={cardClass}>
                <CircularProgress />
            </Card>
        )
    }

    return (
        <Card className={cardClass} style={{ minHeight: buttonsVisible ? '400px' : '350px'}}>
            <CardActionArea onClick={navigateToAlbum} onMouseDown={mouseDownHandler}>
                {album.thumbnail ?
                    imageLoaded ? <div className={cx({[classes.media]: true, [classes.mediaNotPublished]: !album.published})} style={{backgroundImage: `url(${thumbnail.src})`}}/> : <div className={classes.media}><CircularProgress className={classes.progress}/></div> :
                    <div className={classes.media} />
                }

                <CardContent style={{paddingBottom: '0px', paddingTop: '4px'}}>
                    <Typography variant="h5" className={classes.albumTitle} style={{fontSize: getDynamicFontSize(album.title.length)}} component="h2">
                        {album.title}
                    </Typography>
                    {album.created_by !== null &&
                        <Typography className={classes.createdByText} variant="caption" color="textSecondary" component="p">
                            Aangemaakt door {getInitialUploader(userToString(album.created_by))}
                        </Typography>
                    }
                    {!album.published &&
                        <Typography variant="caption" gutterBottom color="textSecondary">
                            Niet gepubliceerd
                        </Typography>
                    }
                    <Typography variant="subtitle2" color="textSecondary" component="p">
                        {album.child_album_amount > 0 && `Aantal albums: ${album.child_album_amount} -`} Aantal foto's: {album.photo_amount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {album.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardContent style={{paddingTop: '4px', paddingBottom: '4px', maxHeight: '50px'}}>
                {album.tags.map((tag, i) => {
                   return <Chip key={i} className={classes.chip} size="small" label={tag.name}/>
                })}
            </CardContent>
            <CardActions>
                <Box>
                    {(user.album_change_permission && album.featured && showFeaturedButton) &&
                        <Tooltip title="Maak niet meer featured">
                            <IconButton onClick={() => setFeatured(false)}>
                                <Star className={classes.favorite}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {(user.album_change_permission && !album.featured && showFeaturedButton) &&
                        <Tooltip title="Maak featured">
                            <IconButton onClick={() => setFeatured(true)}>
                                <StarBorder/>
                            </IconButton>
                        </Tooltip>
                    }

                    {user.album_change_permission && album.published &&
                        <Tooltip title="Publiceren ongedaan maken">
                            <IconButton onClick={() => setPublished(false)}>
                                <Explore/>
                            </IconButton>
                        </Tooltip>
                    }
                    {user.album_change_permission && !album.published &&
                        <Tooltip title="Album publiceren">
                            <IconButton onClick={() => setPublished(true)}>
                                <ExploreOff/>
                            </IconButton>
                        </Tooltip>
                    }

                    {user.album_change_permission &&
                        <Tooltip title="Album aanpassen">
                            <IconButton onClick={openEditDialog}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                    }

                    {user.album_delete_permission &&
                        <IconButton onClick={openDeleteDialog}>
                            <Delete/>
                        </IconButton>
                    }

                    {user.album_download_permission &&
                        <IconButton onClick={openDownloadDialog}>
                            <CloudDownload/>
                        </IconButton>
                    }
                </Box>
            </CardActions>
            <AlbumEditDialog user={user} album={album} reloadAlbum={reloadAlbum} open={editDialogOpen} onClose={closeEditDialog}/>
            <DeleteAlbumDialog user={user} album={album} open={deleteDialogOpen} onClose={closeDeleteDialog} />
            <DownloadAlbumDialog user={user} album={album} open={downloadDialogOpen} onClose={closeDownloadDialog}/>
        </Card>
    )
}

export default AlbumCard;