export var MediaItem;
(function (MediaItem) {
    MediaItem[MediaItem["IMAGE"] = 0] = "IMAGE";
    MediaItem[MediaItem["VIDEO"] = 1] = "VIDEO";
})(MediaItem || (MediaItem = {}));
export var SortTypes;
(function (SortTypes) {
    SortTypes[SortTypes["NEWEST_ASC"] = 0] = "NEWEST_ASC";
    SortTypes[SortTypes["NEWEST_DESC"] = 1] = "NEWEST_DESC";
    SortTypes[SortTypes["DATE_ASC"] = 2] = "DATE_ASC";
    SortTypes[SortTypes["DATE_DESC"] = 3] = "DATE_DESC";
    SortTypes[SortTypes["MOST_LIKED_PHOTO"] = 4] = "MOST_LIKED_PHOTO";
    SortTypes[SortTypes["MOST_LIKED_ALBUM"] = 5] = "MOST_LIKED_ALBUM";
    SortTypes[SortTypes["LAST_UPDATED_ALBUM"] = 6] = "LAST_UPDATED_ALBUM";
})(SortTypes || (SortTypes = {}));
export var CheckTypes;
(function (CheckTypes) {
    CheckTypes[CheckTypes["ACCEPTED"] = 0] = "ACCEPTED";
    CheckTypes[CheckTypes["NOT_ACCEPTED"] = 1] = "NOT_ACCEPTED";
})(CheckTypes || (CheckTypes = {}));
