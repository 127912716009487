import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

function DownloadAlbumDialog(props) {
    const classes = useStyles();

    const {open, onClose, album, user, autoDownload} = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ loadingState, setLoadingState ] = useState(true);
    const [ packageAvailable, setPackageAvailable ] = useState(false);
    const [ triggerAutoDownload, setTriggerAutoDownload ] = useState(autoDownload);

    const packageUrl = `/api/album/${album.id}/package/`;

    useEffect(() => {
        if (open || triggerAutoDownload) {
            if (triggerAutoDownload) {
                setTriggerAutoDownload(false);
            }
            setLoadingState(true);
            axios.head(packageUrl).then((res) => {
                setLoadingState(false);
                if (res.status === 200) {
                    setPackageAvailable(true);
                    if (triggerAutoDownload) {
                        window.location.assign(packageUrl)
                    }
                }
            }).catch((error) => {
                setLoadingState(false);
            });
        }

    }, [open, autoDownload]);

    const requestAlbumPackage = () => {
        axios.post(packageUrl).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: res.data.success ? "success" : "error"});
            onClose();
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        });
    }

    const dialogContentNotAvailable = [
        <DialogContent key={'content'}>
            <Typography variant="body1" component="p">
                Met deze optie kun je heel het album "{album.title}" downloaden.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                Alle {album.photo_amount} foto's worden op de achtergrond voor je ingepakt.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                Zodra het inpakken klaar is krijg je een email op {user.email} met een link naar het bestand dat voor je klaar staat!
            </Typography>
        </DialogContent>,
        <DialogActions key={'actions'}>
            <Button onClick={requestAlbumPackage}>
                Start met inpakken!
            </Button>
            <Button onClick={onClose}>
                Nee
            </Button>
        </DialogActions>
    ]

    const dialogContentAvailable = [
            <DialogContent key={'content'}>
                <Typography variant="body1" component="p">
                    Met deze optie kun je heel het album "{album.title}" downloaden.
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Alle {album.photo_amount} foto's staan klaar om te downloaden!
                </Typography>
            </DialogContent>,
            <DialogActions key={'actions'}>
                <Button onClick={() => window.location.assign(packageUrl)}>
                    Downloaden!
                </Button>
                <Button onClick={onClose}>
                    Nee
                </Button>
            </DialogActions>
    ]

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>
                Album downloaden?
            </DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            {loadingState ?
                <DialogContent>
                    <Box display="flex" justifyContent="center" mb={4}>
                        <CircularProgress/>
                    </Box>
                </DialogContent>
                : packageAvailable ?
                    dialogContentAvailable : dialogContentNotAvailable
            }

        </Dialog>
    )
}

export default DownloadAlbumDialog;