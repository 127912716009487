import React, {useState} from 'react';
import {Fade, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip} from "@material-ui/core";
import userToString from "../Utils/Utils";
import {makeStyles} from "@material-ui/styles";
import dayjs from "dayjs";
import IconButton from "@material-ui/core/IconButton";
import {Cancel, Check, PhotoAlbum} from "@material-ui/icons";
import MediaCard from "./MediaCard";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import {MediaItem} from "../models/Types";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        width: "200px",
        marginRight: theme.spacing(2)
    },
    checkButton: {
        marginRight: theme.spacing(4),
        color: theme.palette.success.main
    },
    cancelButton: {
        marginRight: theme.spacing(4),
        color: theme.palette.error.main
    },
    albumButton: {
        marginRight: theme.spacing(4)
    },
    lightBox: {
        zIndex: 1600
    }
}));

const MediaItemReport = (props) => {
    const {report, deleteReport} = props;
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [backdropOpen, setBackdropOpen] = useState(false);

    const updateReport = (resolve) => {
        axios.post(`/api/report/${report.id}/`, {resolve}).then((res) => {
            deleteReport(report.id);
            enqueueSnackbar(res.data.msg, {variant: res.data.variant});
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        });
    }

    return (
        <Fade in timeout={300}>
            <div>
                <ListItem className={classes.root} alignItems="flex-start" dense={true}>
                    <ListItemAvatar className={classes.avatar} onClick={() => setBackdropOpen(true)}>
                        <MediaCard mediaItem={report.media_item}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`Ingediend door: ${userToString(report.reported_by)}`}
                        secondary={<React.Fragment>
                                <Typography variant="body2">Op: {dayjs(report.created_on).format("DD-MM-YYYY HH:mm")}</Typography>
                                <Typography variant="body2">Wordt automatisch teruggeplaatst op: {dayjs(report.expires_at).format("DD-MM-YYYY HH:mm")}</Typography>
                            </React.Fragment>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title={"Album openen"}>
                            <IconButton edge="end" aria-label="comments" className={classes.albumButton} onClick={() => history.push(`/album/${report.media_item.album}/`)}>
                                <PhotoAlbum fontSize="large"/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Foto verwijderen"}>
                            <IconButton edge="end" aria-label="comments" className={classes.checkButton} onClick={() => updateReport(true)}>
                                <Check fontSize="large"/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Foto terugplaatsen in album"}>
                            <IconButton edge="end" aria-label="comments" className={classes.cancelButton} onClick={() => updateReport(false)}>
                                <Cancel fontSize="large"/>
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
                <Backdrop onClick={() => setBackdropOpen(false)} className={classes.lightBox} open={backdropOpen}>
                    {report.media_item.type === MediaItem.IMAGE && <img src={report.media_item.medium_view.file}/>}
                    {report.media_item.type === MediaItem.VIDEO &&
                    <video key={report.media_item.id} controls>
                        <source src={report.media_item.full_size.file} type="video/mp4"/>
                    </video>}
                </Backdrop>
            </div>
        </Fade>
    );
};

export default MediaItemReport;