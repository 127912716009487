import React, {useMemo, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import {Delete, ThumbUp} from "@material-ui/icons";
import axios from "axios";
import {useSnackbar} from "notistack";
import DeleteCommentDialog from "../Dialogs/DeleteCommentDialog";
import userToString from "../Utils/Utils";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";

const style = (theme) => ({
    name: {
        fontWeight: "bold"
    },
    comment: {
        background: theme.palette.action.disabledBackground,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        borderRadius: '10px'
    },
    icons: {
        fontSize: '20px',
        cursor: 'pointer',
        color: theme.palette.grey
    },
    likedIcon: {
        color: theme.palette.success.main
    },
    deleteIcon: {
        color: theme.palette.secondary.main
    },
    tooltip: {
        cursor: 'pointer'
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function MediaItemComment(props) {
    const {onDelete, user, mediaItem} = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [comment, setComment] = useState(props.comment);

    const [deleteCommentDialogOpen, setDeleteCommentDialogOpen] = useState(false);
    const canDelete = useMemo(() => user.comment_delete_permission || user.id === comment.user.id, [comment, user]);
    const liked = useMemo(() => comment.likes.reduce((acc, like) => {
        like.id === user.id && (acc = true)
        return acc;
    }, false), [comment, user]);

    const fail = (error) => {
        if (error.response) {
            enqueueSnackbar(error.response.msg, {variant: "error"});
        }
    }

    const like = () => {
        if (!liked) {
            axios.put(`/api/media/${mediaItem.id}/comments/${comment.id}/like/`).then((res) => {
                if (res.data.success) {
                    comment.likes.push(user);
                    setComment({...comment});
                }
            }).catch(fail);
        } else {
            axios.delete(`/api/media/${mediaItem.id}/comments/${comment.id}/like/`).then((res) => {
                if (res.data.success) {
                    comment.likes.map((like) => like.id === user.id && comment.likes.pop(like));
                    setComment({...comment});
                }
            }).catch(fail);
        }
    }

    return (
        <div className={classes.comment}>
            <Box display="flex" alignItems="center">
                <Box>
                    <Typography className={classes.name} variant="body1" component="p">
                        {userToString(comment.user)}
                    </Typography>
                </Box>
                <Box ml={1}>
                    <ThumbUp className={cx({[classes.icons]: true, [classes.likedIcon]: liked})} onClick={like}/>
                </Box>
                <Box ml={1} flexGrow={1}>
                    {comment.likes.length > 0 &&
                        <Tooltip
                            placement="bottom-start"
                            className={classes.tooltip}
                            title={
                            <React.Fragment>
                                {comment.likes.map((like, i) => (
                                    <Typography key={i} variant="caption" component="p">
                                        {userToString(like)}
                                    </Typography>
                                ))}
                            </React.Fragment>
                        }>
                            <Typography variant="caption" component="p">
                                {comment.likes.length}x leuk gevonden
                            </Typography>
                        </Tooltip>
                    }
                </Box>
                {canDelete &&
                    <Delete onClick={() => setDeleteCommentDialogOpen(true)} className={cx([classes.icons, classes.deleteIcon])}/>
                }
            </Box>

            <Typography variant="body2" component="span">
                {comment.text}
            </Typography>

            <DeleteCommentDialog open={deleteCommentDialogOpen} onClose={() => setDeleteCommentDialogOpen(false)} onDelete={() => {setDeleteCommentDialogOpen(false); onDelete(comment);}}/>
        </div>
    )
}

export default MediaItemComment;