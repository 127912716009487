import React, {useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

import ScorpiosLogo from "../assets/img/logo_scorpios.png";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        left: "20px",
        bottom: "20px",
        maxWidth: "80%"
    },
    flexbox: {
        cursor: "pointer"
    },
    footerText: {
        fontSize: "0.8em"
    },
    text: {
        textAlign: "justify",
        textJustify: "inter-word",
        marginBottom: theme.spacing(1)
    },
    logo: {
        margin: 1,
        width: '20px',
        height: '20px'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function MadeByComponent(props) {
    const classes = useStyles();
    const { onBottom } = props;

    const [dialogOpen, setDialogOpen] = useState(false);

    const open = () => {
        setDialogOpen(true);
    }

    const close = () => {
        setDialogOpen(false);
    }

    return (
        <div className={classes.root}>
            <Grow in={onBottom} >
                <Box display="flex" alignItems="center" className={classes.flexbox} onClick={open}>
                    <img className={classes.logo} src={ScorpiosLogo}/>
                    <Typography className={classes.footerText} variant="body1" component="p">
                        Gemaakt door College Scorpios
                    </Typography>
                </Box>
            </Grow>

            <Dialog open={dialogOpen} onClose={close}>
                <DialogTitle>
                    Over DSB Fotoplatform
                </DialogTitle>
                <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography className={classes.text} variant="body2" component="p">
                        Gemaakt door Sem van den Broek als opdracht tot collegewording van Jaargroep Scorpios.
                    </Typography>
                    <Typography className={classes.text} variant="body2" component="p">
                        Dit fotoplatform, samen met alle gescande foto's vormden de collegeopdracht voor Scorpios, die in 2017 verkregen is.
                    </Typography>
                    <Typography className={classes.text} variant="body2" component="p">
                        Opdracht afgerond in juni 2021
                    </Typography>
                    <Typography className={classes.text} variant="body2" component="p">
                        Laatst geupdate juni 2021
                    </Typography>
                    <Typography className={classes.text} variant="body2" component="p">
                        Vrij te gebruiken door de Delftsche Studenten Bond, met content in eigendom van de Delftsche Studenten Bond. Licensering aan derden alleen toegestaan met expliciete toestemming van voorgenoemd persoon.
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default MadeByComponent;
