import React, {useContext, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import UserTag from "./UserTag";
import PhotoTag from "./PhotoTag";
import CommentSection from "./CommentSection";
import userToString from "../Utils/Utils";
import {getInitialUploader} from "../models/InitialUploaders";
import ItemReportDialog from "../Dialogs/ItemReportDialog";
import {MediaItem} from "../models/Types";
import {MediaContext} from "../Contexts/MediaContext";

const style = (theme) => ({
    comments: {
        height: '400px'
    },
    uploadText: {
        fontStyle: 'italic'
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function SocialBar(props) {
    const classes = useStyles();

    const {user} = props;
    const {mediaItem} = useContext(MediaContext);

    return (
        <Box>
            <Box mt={2}>
                <Typography variant="caption" component="p">
                    {mediaItem.title}
                </Typography>
                {mediaItem.uploaded_by !== null &&
                    <Typography className={classes.uploadText} variant="caption" component="p">
                        Geupload door: {getInitialUploader(userToString(mediaItem.uploaded_by))}
                    </Typography>
                }
            </Box>
            <Box mt={2}>
                <PhotoTag user={user} photo={mediaItem}/>
            </Box>
            <Box mt={2}>
                <UserTag user={user} photo={mediaItem}/>
            </Box>
            <Box mt={2} className={classes.comments}>
                <CommentSection user={user} photo={mediaItem}/>
            </Box>
        </Box>

    )
}

export default SocialBar;