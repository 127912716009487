import React, {createContext, useContext, useEffect, useRef, useState} from 'react';

const WebSocketContext = createContext(null);

export const useWebsocket = (url) => {
    const ctx = useContext(WebSocketContext)

    if (url) {
        useEffect(() => {
            ctx.setUrl(url)
        }, []);
    }

    return {ws: ctx.ws, open: ctx.wsOpen};
};

const WebSocketProvider = ({children}) => {

    const [url, setUrl] = useState(null);
    const [reconnect, setReconnect] = useState(true);
    const [wsOpen, setWsOpen] = useState(false);

    const ws = useRef(null);

    const onOpen = () => {
        setWsOpen(true);
    };

    const onClose = () => {
        setWsOpen(false);
        setTimeout(() => {
            setReconnect(true);
        }, 1000);
    }

    useEffect(() => {
        if (url) {
            if (ws.current) {
                ws.current.close();
            }

            setReconnect(true);
        }
    }, [url]);

    useEffect(() => {
        if (url && reconnect) {
            ws.current = new WebSocket(
                `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${window.location.host}${url}`
            );

            ws.current.onopen = onOpen;
            ws.current.onclose = onClose;
            setReconnect(false);
        }
    }, [url, reconnect]);

    return (
        <WebSocketContext.Provider value={{ws: ws.current, setUrl, wsOpen}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;