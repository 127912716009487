import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";
import axios from "axios";

function DeleteAlbumDialog(props) {

    const { enqueueSnackbar } = useSnackbar();
    const {user, album, open, onClose} = props;

    const deleteAlbum = () => {
        return axios.delete(`/api/album/${album.id}/`).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: "success"});
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        }).finally(() => {
            onClose();
        })
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Album verwijderen?
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="p">
                    Weet je zeker dat je album: {album.title} wilt verwijderen?
                </Typography>
                <Typography variant="body2" ccolor="textSecondary" component="p">
                    Alle {album.photo_amount} foto's in het album worden hiermee ook automatisch verwijderd!
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={deleteAlbum}>
                    Ja
                </Button>
                <Button onClick={onClose}>
                    Nee
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteAlbumDialog;