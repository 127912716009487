import React, {useContext, useEffect, useState} from "react";
import MediaItemComment from "./MediaItemComment";
import axios from "axios";
import {useSnackbar} from "notistack";
import {MediaContext} from "../Contexts/MediaContext";

function MediaItemCommentComponent(props) {
    const {user} = props;

    const { enqueueSnackbar } = useSnackbar();

    const {mediaItem, setMediaItem} = useContext(MediaContext);

    const deleteComment = (comment) => {
        axios.delete(`/api/media/${mediaItem.id}/comments/${comment.id}/`).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: 'success'})
            mediaItem.comments.map((search, i) => search.id === comment.id && mediaItem.comments.splice(i, 1))
            setMediaItem({...mediaItem});
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        });
    }

    return (
        <div>
            {mediaItem.comments.map((comment) => {
                // Math.random() because we want the entire comment to be rerendered
                return <MediaItemComment key={Math.random()} mediaItem={mediaItem} comment={comment} user={user} onDelete={deleteComment}/>
            })}
        </div>
    )
}

export default MediaItemCommentComponent;