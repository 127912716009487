import React from "react";
import {Typography} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import userToString from "../Utils/Utils";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        cursor: 'pointer'
    }
}));

function LikeComponent(props) {
    const {prefix, likes} = props;
    const classes = useStyles();

    const likeText = prefix ? `${prefix} leuk gevonden door:` : "Leuk gevonden door:";

    if (likes.length > 3) {
        return (
            <Tooltip
                placement="top-start"
                className={classes.tooltip}
                title={
                    <React.Fragment>
                        {likes.map((like, i) => {
                            return <Typography key={i} variant="caption" component="p">
                                {userToString(like)}
                            </Typography>
                        })}
                    </React.Fragment>
                }>
                <Typography variant="subtitle2">
                    {likeText} {userToString(likes[0])}, {userToString(likes[1])} en {likes.length - 2} anderen
                </Typography>
            </Tooltip>
        )
    }

    return (
        likes.length === 0 ?
            <React.Fragment/> :
            likes.length === 1 ?
                <Typography variant="subtitle2">
                    {likeText} {userToString(likes[0])}
                </Typography> :
                likes.length === 2 ?
                    <Typography variant="subtitle2">
                        {likeText} {userToString(likes[0])} en {userToString(likes[1])}
                    </Typography> :
                    likes.length === 3 &&
                        <Typography variant="subtitle2">
                            {likeText} {userToString(likes[0])}, {userToString(likes[1])} en {userToString(likes[2])}
                        </Typography>
    )
}

export default LikeComponent;