import React, {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useSnackbar} from "notistack";
import Chip from "@material-ui/core/Chip";
import {Typography} from "@material-ui/core";

function UserTag(props) {
    const {user, photo} = props;
    const { enqueueSnackbar } = useSnackbar();

    const [users, setUsers] = useState([]);
    const [taggedUsers, setTaggedUsers] = useState([]);

    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const loading = open && users.length === 0;

    const nonPickedUsers = useMemo(() => users.filter((user) => taggedUsers.indexOf(user) === -1), [users, taggedUsers]);

    const textBox = useRef(null);

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        textBox.current?.addEventListener('keydown', stopPropagation);
        return () => textBox.current?.removeEventListener('keydown', stopPropagation);
    }, [textBox]);

    useEffect(() => {
        setTaggedUsers(photo.usertags);
    }, [photo])

    useEffect(() => {
        axios.get('/api/user/').then((res) => {
            setUsers(res.data);
        });
    }, []);

    const changeTagged = (e, newValue) => {
        photo.usertags = newValue;
        setTaggedUsers(newValue);

        axios.post(`/api/media/${photo.id}/usertag/`, {tagged: newValue}).then((res) => {
            console.log(res);
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        })
    }

    return (
        <div>
            {user.usertag_change_permission ?
                <Autocomplete
                    multiple
                    limitTags={2}
                    open={autocompleteOpen}
                    onOpen={() => setAutocompleteOpen(true)}
                    onClose={() => setAutocompleteOpen(false)}
                    options={nonPickedUsers}
                    loading={loading}
                    value={taggedUsers}
                    onChange={changeTagged}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                    renderInput={(params) => <TextField {...params} ref={textBox} label="Gebruikers taggen" variant="outlined" placeholder="Tag iemand"/>}
                /> :
                taggedUsers.length > 0 &&
                    <div>
                        <Typography variant="caption" component="p">Getagde gebruikers:</Typography>
                        {taggedUsers.map((user, i) => {
                            return <Chip key={i} size="small" label={`${user.first_name} ${user.last_name}`}/>
                        })}
                    </div>
            }
        </div>
    )
}

export default UserTag;