import React from 'react';
import ReactDOM from 'react-dom';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import cyan from "@material-ui/core/colors/cyan";
import deepOrange from "@material-ui/core/colors/deepOrange";
import Main from "./Main";
import UserProvider from "./Contexts/UserProvider";

const theme = createMuiTheme({
    palette: {
        type: "light",
        primary: cyan,
        secondary: deepOrange,
        background: {
            default: "#ecf4f7"
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});

const useStyles = makeStyles((theme) => ({
    body: {
        margin: 0
    }
}));

function App() {
    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
                <BrowserRouter>
                    <UserProvider>
                        <Main/>
                    </UserProvider>
                </BrowserRouter>
            </SnackbarProvider>
        </MuiThemeProvider>
    )
}

ReactDOM.render(<App/>, document.querySelector('#app'));

if (module && module.hot) module.hot.accept();