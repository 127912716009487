import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Fade,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import MediaItemReport from "../Components/MediaItemReport";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    listItem: {
        backgroundColor: theme.palette.background.paper
    },
    listItemAvatar: {
        width: "200px",
        marginRight: theme.spacing(2)
    },
}));

function ReviewPage() {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        axios.get("/api/media/reports/").then(res => {
            setReports(res.data);
            setLoading(false);
        }).catch(error => {
            setError(true);
        });
    }, []);

    const deleteReport = (id) => {
        const newReports = reports.filter((report) => report.id !== id);
        setReports([...newReports]);
    }

    if (error) {
        return (
            <div>
                <Typography variant="h5">
                    Ophalen van gerapporteerde foto's mislukt.
                </Typography>
                <Typography variant="body1">
                    Controleer of je de juiste rechten hebt om deze pagina te mogen bekijken.
                </Typography>
            </div>
        )
    }

    return (
        <div>
            <Typography variant="h4" component="h4">
                Gerapporteerde foto's:
            </Typography>
            {loading ?
                <List>
                    {
                        [...Array(30)].map((e, i) => {
                            return (
                                <Fade in timeout={1500}>
                                    <div>
                                        <ListItem key={i} className={classes.listItem} alignItems="flex-start" dense={true}>
                                            <ListItemAvatar className={classes.listItemAvatar}>
                                                <Skeleton variant="rect" height={140} width={200}/>
                                            </ListItemAvatar>
                                            <ListItemText primary={<Skeleton variant="text" width={300}/>} secondary={<Skeleton variant="text" width={200}/>}/>
                                            <ListItemSecondaryAction>
                                                <Box display="flex">
                                                    <Box mr={4}>
                                                        <Skeleton variant="circle" width={50} height={50}/>
                                                    </Box>
                                                    <Box mr={4}>
                                                        <Skeleton variant="circle" width={50} height={50}/>
                                                    </Box>
                                                </Box>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider/>
                                    </div>
                                </Fade>
                            )
                        })
                    }
                </List> :
                reports.length === 0 ?
                    <Typography variant="body1" component="p">
                        Geen gerapporteerde foto's gevonden
                    </Typography> :

                    <List>
                        {reports.map((report, i) => <MediaItemReport key={report.id} report={report} deleteReport={deleteReport}/>)}
                    </List>
            }
        </div>
    )
}

export default ReviewPage;