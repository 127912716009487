import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";
import axios from "axios";

function DeleteCommentDialog(props) {
    const {open, onClose, onDelete} = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Comment verwijderen?
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="p">
                    Weet je zeker dat je deze comment wilt verwijderen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDelete}>
                    Ja
                </Button>
                <Button onClick={onClose}>
                    Nee
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteCommentDialog;