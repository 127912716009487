import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import axios from 'axios';
import AlbumView from "../Components/AlbumView";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AlbumCreateDialog from "../Dialogs/AlbumCreateDialog";
import AlbumSearchComponent from "../Components/AlbumSearchComponent";
import {Box} from "@material-ui/core";
import {useUser} from "../Contexts/UserProvider";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        '&.MuiFab-secondary': {
            bottom: theme.spacing(4),
            right: theme.spacing(4)
        }
    }
}));

function HomePage(props) {
    const classes = useStyles();
    const {onLoad} = props;

    const {user} = useUser();

    const [albumCreateDialogOpen, setAlbumCreateDialogOpen] = useState(false);

    const [doReloadAll, setDoReloadAll] = useState(false);

    const handleAlbumCreateDialogClose = () => {
        setAlbumCreateDialogOpen(false);
        setDoReloadAll(true);
    }

    return(
        <div>
            <Box display="flex" flexDirection="row-reverse">
                <AlbumSearchComponent />
            </Box>
            <AlbumView id={0} name={"Featured albums"} queryString={"featured=true"} skeletonRows={1} oneLine={true} showLoading={true} onLoad={onLoad} user={user}/>
            <AlbumView id={1} name={"All albums"} user={user} sortEnabled={true} skeletonRows={2} onLoad={onLoad} parentReload={doReloadAll} showLoading={true} setParentReload={setDoReloadAll}/>

            {user.album_add_permission &&
                <Fab className={classes.fab} onClick={() => setAlbumCreateDialogOpen(true)} color="secondary" aria-label="add">
                  <AddIcon />
                </Fab>
            }

            <AlbumCreateDialog user={user} open={albumCreateDialogOpen} onClose={handleAlbumCreateDialogClose}/>
        </div>
    )
}

export default HomePage;