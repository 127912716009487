import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import axios from 'axios';
import AlbumView from "../Components/AlbumView";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AlbumCreateDialog from "../Dialogs/AlbumCreateDialog";
import AlbumSearchComponent from "../Components/AlbumSearchComponent";
import {Box, Typography} from "@material-ui/core";
import {useUser} from "../Contexts/UserProvider";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        '&.MuiFab-secondary': {
            bottom: theme.spacing(4),
            right: theme.spacing(4)
        }
    }
}));

function PersonalPage(props) {
    const classes = useStyles();
    const {onLoad} = props;

    const {user} = useUser();

    const [albumCreateDialogOpen, setAlbumCreateDialogOpen] = useState(false);

    const [doReloadAll, setDoReloadAll] = useState(false);

    const handleAlbumCreateDialogClose = () => {
        setAlbumCreateDialogOpen(false);
        setDoReloadAll(true);
    }

    return(
        <div>
            <Box display="flex" flexDirection="row-reverse" alignItems="center">
                <AlbumSearchComponent />

                <Box flexGrow={1}>
                    <Typography>Hier kun je albums vinden die je leuk vindt, en waar je in getagd bent</Typography>
                </Box>

            </Box>
            <AlbumView id={2} name={"Albums die je leuk vindt"} queryString={"personal_liked=true"} skeletonRows={1} oneLine={true} onLoad={onLoad} user={user} hideEmpty={true}/>
            <AlbumView id={3} name={"Albums met foto's waar je in getagd bent"} queryString={"personal_tagged=true"} skeletonRows={1} oneLine={true} onLoad={onLoad} user={user} hideEmpty={true}/>
            <AlbumView id={4} name={"Albums die jij hebt aangemaakt"} queryString={"personal_created=true"} skeletonRows={2} onLoad={onLoad} user={user} hideEmpty={true} showLoading={true} sortEnabled={true}/>

            {user.album_add_permission &&
                <Fab className={classes.fab} onClick={() => setAlbumCreateDialogOpen(true)} color="secondary" aria-label="add">
                  <AddIcon />
                </Fab>
            }

            <AlbumCreateDialog user={user} open={albumCreateDialogOpen} onClose={handleAlbumCreateDialogClose}/>
        </div>
    )
}

export default PersonalPage;