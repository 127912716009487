import React, {useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Box, Button, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dropzone, {useDropzone} from "react-dropzone";
import Icon from "@material-ui/core/Icon";
import Backup from "@material-ui/icons/Backup";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dropSection: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
        borderRadius: 10,
        borderStyle: "dotted"
    },
    progBar: {
        marginTop: theme.spacing(2)
    }
}));

function UploadDialog(props) {
    const classes = useStyles();
    const {album, onUploadFinished, open, onClose} = props;
    const { enqueueSnackbar } = useSnackbar();

    const [files, setFiles] = useState([]);

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const uploadPhoto = (file) => {
        const formData = new FormData();
        formData.append("title", file.name.slice(0, 50));
        formData.append("file", file, file.name)
        formData.append("album", album.id);
        return axios.post("/api/media/", formData);
    }

    const handleFileUpload = () => {
        if (files.length === 0) {
            enqueueSnackbar("Geen bestanden geselecteerd", {variant: "error"})
        } else {
            setUploading(true);
            let photosUploaded = 0;

            const uploadFiles = [...files];
            const initialLength = uploadFiles.length;

            // Try uploading first file
            const firstFile = uploadFiles.shift();
            uploadPhoto(firstFile).then((res) => {
                if (initialLength === 1) {
                    setUploading(false);
                    setFiles([]);
                    onUploadFinished && onUploadFinished();
                    enqueueSnackbar("Bestand geupload!", {variant: "success"});
                    onClose()
                } else {
                    // First photo succesful, continueing with the rest
                    photosUploaded++;
                    uploadFiles.map((file, i) => {
                        uploadPhoto(file).then(() => {
                            photosUploaded++;
                            setProgress(photosUploaded / initialLength * 100);
                        }).catch((err) => {
                            console.log(`Bestand ${file.name} upload failed. Maybe the file was too large?`)
                            enqueueSnackbar(`Bestand ${file.name} niet geupload! Het bestand was misschien te groot.`, {variant: "warning", persist: true});
                            photosUploaded++;
                            setProgress(photosUploaded / initialLength * 100);
                        }).finally(() => {
                            if (photosUploaded / initialLength * 100 === 100) {
                                setUploading(false);
                                setFiles([]);
                                onUploadFinished && onUploadFinished();
                                enqueueSnackbar("Bestanden geupload!", {variant: "success"});
                                onClose()
                            }
                        })
                    });
                }
            }).catch((err) => {
                setUploading(false);
                if (err.response) {
                    if (err.response.status === 403 || err.response.status === 400) {
                        enqueueSnackbar(err.response.data.msg, {variant: "error"})
                    }
                } else {
                    console.log(`MediaItem ${file.name} upload failed. Maybe the file was too large?`)
                    enqueueSnackbar("Er gaat iets fout bij het uploaden van de bestanden", {variant: "error"})
                }
            })
        }
    }

    const onDropFiles = (files) => {
        setFiles(files);
    }

    const tryClose = () => {
        if (!uploading) {
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={tryClose} fullWidth={true} maxWidth="md" scroll="body">
            <DialogTitle>
                Media uploaden naar album: {album.title}
            </DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={tryClose} disabled={uploading}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography variant="body2">
                    Foto's moeten vooraf goed gedraaid staan en in sRGB kleurruimte worden geupload voor een correcte representatie.
                </Typography>
                <Dropzone onDrop={onDropFiles} disabled={uploading} accept={'.jpeg, .jpg, image/tiff, image/png, .mp4'}>
                    {({getRootProps, getInputProps}) => (
                        <section >
                            <div {...getRootProps()} className={classes.dropSection}>
                                <input {...getInputProps()} />
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box mr={2}>
                                        <Backup/>
                                    </Box>
                                    <Typography variant="h5" component="h5">
                                        Klik hier of sleep je bestanden hier naartoe
                                    </Typography>
                                </Box>
                            </div>
                        </section>
                    )}
                </Dropzone>

                <Typography variant="body1">
                    {files.length} bestanden geselecteerd
                </Typography>

                {uploading && (
                    progress === 0 ?
                        <LinearProgress className={classes.progBar} variant="indeterminate"/>
                        :
                        <LinearProgress className={classes.progBar} variant="determinate" value={progress}/>
                )
                }
            </DialogContent>
            <DialogActions>
                {!uploading && [
                    <Button color="primary" onClick={tryClose}>
                        Cancel
                    </Button>,
                    <Button color="primary" onClick={handleFileUpload}>
                        Upload
                    </Button>
                ]}
            </DialogActions>
        </Dialog>
    )
}

export default UploadDialog;