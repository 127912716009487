import React, {useContext} from "react";
import {Button, Dialog, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {MediaContext} from "../Contexts/MediaContext";
import {MediaItem} from "../models/Types";
import axios from "axios";
import {useSnackbar} from "notistack";
import {useConfig} from "../Contexts/SimpleDataProvider";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    warningText: {
        color: theme.palette.warning.main
    }
}));

function ItemReportDialog(props) {
    const {open, onClose, onDeletePhoto} = props;

    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const {mediaItem} = useContext(MediaContext);

    const {config} = useConfig();

    const reportItem = () => {
        return axios.put(`/api/media/${mediaItem.id}/report/`).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: res.data.variant});
            if (res.data.success) {
                onDeletePhoto();
            }
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        }).finally(() => {
            onClose();
        });
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {mediaItem.type === MediaItem.IMAGE ? "Foto": "Video"} rapporteren
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="p">
                    Staat er iets ongepasts op de {mediaItem.type === MediaItem.IMAGE ? "foto": "video"}? Of sta je zelf op deze {mediaItem.type === MediaItem.IMAGE ? "foto": "video"} en wil je dat liever niet? Geef het aan!
                </Typography>
                <Typography variant="body1" component="p">
                    Deze {mediaItem.type === MediaItem.IMAGE ? "foto": "video"} wordt dan direct onzichtbaar voor de komende {config.MEDIAITEM_REPORT_INVISIBLE_DAYS} dagen en het Bestuur krijgt een mailtje om er achteraan te gaan.
                </Typography>
                <Typography variant="body1" component="p">
                    Mocht deze {mediaItem.type === MediaItem.IMAGE ? "foto": "video"} inderdaad ongepast zijn voor het Fotoplatform, dan zal deze permanent verwijderd worden.
                </Typography>
                <Typography variant="body2" component="p" className={classes.warningText}>
                    Let op! Je kunt maximaal {config.MEDIAITEM_MAX_REPORTED_PHOTOS} items tegelijk rapporteren. Is dat niet genoeg? Neem dan contact op met het Bestuur.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={reportItem}>
                    {mediaItem.type === MediaItem.IMAGE ? "Foto": "Video"} rapporteren
                </Button>
                <Button onClick={onClose}>
                    Afsluiten
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ItemReportDialog;