export default function userToString(user) {
    return `${user.first_name} ${user.last_name}`
}

export const getDynamicFontSize = (textLength) => {
    let fontSize = 2.0;

    fontSize -= Math.pow(textLength, 2) * 0.0005;

    if (fontSize < 1) {
        fontSize = 1;
    }

    return `${fontSize}em`
}