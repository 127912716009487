import React, {useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/styles";
import ChipInput from "material-ui-chip-input";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import axios from 'axios';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useSnackbar} from "notistack";
import dayjs from "dayjs";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";
import Grid from "@material-ui/core/Grid";
import {useAlbumList, useGroups, useTags} from "../Contexts/SimpleDataProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '70vh'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    form: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '100%',
            paddingRight: theme.spacing(5)
        },
        '& .MuiFormControlLabel-root': {
            margin: theme.spacing(1),
            width: '100%',
            paddingRight: theme.spacing(5)
        },
    },
}));


function AlbumEditDialog(props) {
    const classes = useStyles();

    const {user, open, onClose, album, reloadAlbum} = props;
    const { enqueueSnackbar } = useSnackbar();

    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(false);

    const [description, setDescription] = useState(null);
    const [descriptionError, setDescriptionError] = useState(false);

    const [longDescription, setLongDescription] = useState("");

    const [albumDate, setAlbumDate] = useState(dayjs());

    const [published, setPublished] = useState(false);
    const [featured, setFeatured] = useState(false);

    const [tags, setTags] = useState([]);
    const [groups, setGroups] = useState([]);

    const [parentAlbum, setParentAlbum] = useState(null);

    const [parentAlbumAutocompleteOpen, setParentAlbumAutocompleteOpen] = useState(false);

    const tagSet = useTags();
    const groupSet = useGroups();

    const availableTags = useMemo(() => tagSet.availableTags.map(tag => tag.name), [tagSet.availableTags]);
    const availableGroups = useMemo(() => groupSet.availableGroups.map(group => group.name), [groupSet.availableGroups]);

    const {availableAlbums} = useAlbumList();

    const [tagSearch, setTagSearch] = useState("");

    useEffect(() => {
        setName(album.title);
        setDescription(album.description);
        setLongDescription(album.long_description);
        setAlbumDate(dayjs(album.album_date));
        setPublished(album.published);
        setFeatured(album.featured);
        setTags(album.tags.map(tag => tag.name))
        setGroups(album.viewable_by.map(group => group.name))
        setParentAlbum(album.parent_album);
    }, [album]);

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changeDescription = (e) => {
        setDescription(e.target.value);
    }

    const changeLongDescription = (e) => {
        setLongDescription(e.target.value);
    }

    const handleAddTag = (tag) => {
        if (tags.indexOf(tag) === -1) {
            const currentTags = tags.slice()
            currentTags.push(tag)
            setTags(currentTags)
        }
    }

    const handleRemoveTag = (tag) => {
        const currentTags = tags.slice()
        const index = currentTags.indexOf(tag)
        if (index > -1) {
            currentTags.splice(index, 1);
            setTags(currentTags)
        }
    }

    const handleAddGroup = (group) => {
        if (groups.indexOf(group) === -1) {
            const currentGroups = groups.slice()
            currentGroups.push(group)
            setGroups(currentGroups)
        }
    }

    const handleRemoveGroup = (group) => {
        const currentGroups = groups.slice()
        const index = currentGroups.indexOf(group)
        if (index > -1) {
            currentGroups.splice(index, 1);
            setGroups(currentGroups)
        }
    }

    const validateInputs = () => {
        setNameError(!name || name === "");
        setDescriptionError(!description || description === "");

        return !(!name || name === "") && !(!description || description === "")
    }

    const editAlbum = () => {
        if (validateInputs()) {
            const data = {
                title: name,
                description: description,
                long_description: longDescription,
                published: published,
                featured: featured,
                album_date: albumDate.format("YYYY-MM-DD"),
                tags: tags,
                viewable_by: groups,
                parent_album: parentAlbum
            }

            axios.post(`/api/album/${album.id}/`, data).then((res) => {
                enqueueSnackbar(res.data.msg, {variant: "success"});
                reloadAlbum();
                onClose();
            }).catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error.response.data.msg, {variant: "error"});
                }
            })
        }
    }

    const changeParentAlbum = (e, newValue) => {
        setParentAlbum(newValue);
    }

    return (
        <Dialog classes={{paper: classes.dialogPaper}} maxWidth="lg" fullWidth={true} open={open} onClose={onClose}>
            <DialogTitle>
                Album bewerken
            </DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography variant="body1" component="p">
                    Vul hier alle details van het album in. Klik op het album en ga naar de pagina om foto's toe te voegen of te verwijderen
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Naam"
                                value={name}
                                helperText="Geef je album een naam"
                                onChange={changeName}
                                error={nameError}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Beschrijving"
                                multiline
                                value={description}
                                rowsMax={2}
                                helperText="Geef je album een korte beschrijving"
                                onChange={changeDescription}
                                error={descriptionError}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Lange beschrijving"
                                multiline
                                value={longDescription}
                                rowsMax={6}
                                helperText="Geef je album een optionele lange beschrijving (gebruik '\n' om een nieuwe regel te maken)"
                                onChange={changeLongDescription}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={DayJsUtils}>
                                <DatePicker onChange={(e) => {setAlbumDate(e)}}
                                            value={albumDate}
                                            format="DD-MM-YYYY"
                                            label="Datum"
                                            helperText="Selecteer de datum van dit album"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel control={<Checkbox checked={published} onChange={(e) => {setPublished(e.target.checked)}} />} label="Gepubliceerd" />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControlLabel control={<Checkbox checked={featured} onChange={(e) => {setFeatured(e.target.checked)}} />} label="Featured maken" />
                        </Grid>
                    </Grid>

                    <ChipInput
                        label="Tags"
                        value={tags}
                        onAdd={handleAddTag}
                        onDelete={handleRemoveTag}
                        newChipKeys={['Enter', 'Tab']}
                        helperText="Voeg tags toe aan je album"
                    />
                    <Box mt={2}>
                        <Accordion TransitionProps={{ unmountOnExit: true }} >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Box display="flex" style={{width: '100%'}} alignItems="center">
                                    <Box flexGrow={1}>
                                        {availableTags.filter(tag => tagSearch !== "" ? tag.toLowerCase().match(tagSearch.toLowerCase()) && tag : tag).slice(0, 10).map((tag, i) => {
                                           return <Chip key={i} className={classes.chip} onClick={(e) => {e.stopPropagation(); handleAddTag(tag);}} size="small" label={tag}/>
                                        })}
                                    </Box>
                                    <Box>
                                        <Typography className={classes.secondaryHeading}>Klik hier voor meer tags</Typography>
                                    </Box>

                                </Box>

                            </AccordionSummary>
                            <AccordionDetails style={{display: 'block'}}>
                                <Box>
                                    <TextField onChange={(e) => setTagSearch(e.target.value)} value={tagSearch} helperText="Zoeken in tags"/>
                                </Box>
                                <Box>
                                    {availableTags.slice(10, availableTags.length).map((tag, i) => {
                                       return <Chip key={i} className={classes.chip} onClick={() => handleAddTag(tag)} size="small" label={tag}/>
                                    })}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <ChipInput
                        label="Groepen"
                        value={groups}
                        onDelete={handleRemoveGroup}
                        newChipKeys={['Enter', 'Tab']}
                        helperText="Voeg groepen toe voor wie je album zichtbaar is"
                    />
                    <Box mt={2}>
                        {availableGroups.map((tag, i) => {
                           return <Chip key={i} className={classes.chip} onClick={() => handleAddGroup(tag)} size="small" label={tag}/>
                        })}
                    </Box>
                    <Autocomplete
                        limitTags={2}
                        open={parentAlbumAutocompleteOpen}
                        onOpen={() => setParentAlbumAutocompleteOpen(true)}
                        onClose={() => setParentAlbumAutocompleteOpen(false)}
                        options={availableAlbums}
                        value={parentAlbum}
                        onChange={changeParentAlbum}
                        getOptionLabel={(option) => `${option.title}`}
                        renderInput={(params) => <TextField {...params} label="Verzamelalbum instellen" variant="outlined" placeholder="Stel een verzamelalbum in"/>}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    Annuleren
                </Button>
                <Button color="primary" onClick={editAlbum}>
                    Bewerken
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlbumEditDialog;