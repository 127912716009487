import React, {useEffect, useMemo, useRef, useState} from "react";
import {useRouteMatch, withRouter} from "react-router-dom";
import axios from 'axios';
import Grid from "@material-ui/core/Grid";
import MediaCard from "../Components/MediaCard";
import Skeleton from "@material-ui/lab/Skeleton";
import {makeStyles} from "@material-ui/styles";
import {AccordionSummary, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import MediaViewDialog from "../Components/MediaViewDialog";
import UploadDialog from "../Components/UploadDialog";
import Box from "@material-ui/core/Box";
import {BrokenImage, CloudDownload, Edit, ExpandMore, Info} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import PageSelector from "../Components/PageSelector";
import DownloadAlbumDialog from "../Dialogs/DownloadAlbumDialog";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tooltip from "@material-ui/core/Tooltip";
import AlbumEditDialog from "../Dialogs/AlbumEditDialog";
import {MediaContext} from "../Contexts/MediaContext";
import LinkText from "../Utils/LinkText";
import AlbumCard from "../Components/AlbumCard";
import AlbumSocialComponent from "../Components/AlbumSocialComponent";
import {useUser} from "../Contexts/UserProvider";
import {useWebsocket} from "../Contexts/WebSocketProvider";

const useStyles = makeStyles((theme) => ({
    dialog: {
        overflow: "hidden"
    },
    image: {
      borderRadius: 10
    },
    accordionHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordionDetails: {
        display: 'block'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    icons: {
        color: theme.palette.text.secondary
    },
    fab: {
        position: 'fixed',
        '&.MuiFab-secondary': {
            bottom: theme.spacing(4),
            right: theme.spacing(4)
        }
    }
}));

function AlbumPage(props) {
    const classes = useStyles();
    const {onLoad} = props;
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();

    const id = useRouteMatch('/album/:id').params.id;
    const {ws} = useWebsocket(`/ws/album/${id}/`);

    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');
    const item = params.get('item');

    const [availableTags, setAvailableTags] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);

    const [album, setAlbum] = useState(null);
    const albumRef = useRef(null);

    const [loading, setLoading] = useState(true);

    const [selectedMediaItemIndex, setSelectedMediaItemIndex] = useState(null);
    const [requestedMediaItemIndex, setRequestedMediaItemIndex] = useState(item ? Number.parseInt(item) : 0);

    const [selectedMediaItem, setSelectedMediaItem] = useState({});
    const [photoViewerOpen, setPhotoViewerOpen] = useState(item !== null);

    const [selectedPage, setSelectedPage] = useState(page ? page : 1);
    const pages = useMemo(() => album ? Math.ceil(album.photo_amount / album.photos_per_page) : 1, [album]);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const setQueryParam = (param, value) => {
        if (value !== null) {
            params.set(param, value);

            if (params.toString() !== "") {
                history.replaceState(null, '', '?' + params.toString());
            } else {
                history.replaceState(null, '', window.location.pathname);
            }
        }
    }

    const removeQueryParam = (param) => {
        params.delete(param)
        if (params.toString() !== "") {
            history.replaceState(null, '', '?' + params.toString());
        } else {
            history.replaceState(null, '', window.location.pathname);
        }
    }

    useEffect(() => {
        const onMessage = async (event) => {
            const album = albumRef.current;
            const data = JSON.parse(event.data);

            if (data.msg || data.error) {
                enqueueSnackbar(data.msg || data.error, {variant: data.variant});
            }

            if (data.type && data.media_item) {
                const found = album.media_items.findIndex(media_item => media_item.id === data.media_item.id);
                switch(data.type) {
                    case "mediaitem_changed":
                        if (found > -1) {
                            album.media_items[found] = data.media_item
                        } else {
                            album.media_items.push(data.media_item);
                        }
                        break;
                    case "mediaitem_deleted":
                        if (found > -1) {
                            album.media_items.splice(found, 1);
                        }
                        break;
                }
                await setAlbum({...album});
            }

            if (data.type && data.type === "album_changed") {
                await setAlbum({...album, ...data.album});
            }
        }

        ws?.addEventListener("message", onMessage);
        return () => ws?.removeEventListener("message", onMessage);
    }, [ws]);

    useEffect(async () => {
        selectedPage !== 1 ? setQueryParam('page', selectedPage) : removeQueryParam('page')
        await reloadAlbum();
    }, [selectedPage, id])

    useEffect(() => {
        setSelectedMediaItem(album?.media_items[selectedMediaItemIndex]);
        photoViewerOpen && setQueryParam('item', selectedMediaItemIndex);
    }, [album, selectedMediaItemIndex]);

    useEffect(() => {
        onLoad();
        albumRef.current = album;
    }, [album]);

    useEffect(() => {
        axios.get('/api/album/tags/').then((res) => {
            setAvailableTags(res.data);
        });
        axios.get('/api/group/').then((res) => {
            setAvailableGroups(res.data);
        })
    }, []);

    document.onkeydown = (e) => {
        if (e.key === "ArrowLeft") {
            if (photoViewerOpen) {
                previousPhoto();
            } else {
                previousPage();
            }
        } else if (e.key === "ArrowRight") {
            if (photoViewerOpen) {
                nextPhoto();
            } else {
                nextPage();
            }
        }
    }

    const reloadAlbum = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`/api/album/${id}/?page=${selectedPage}`);
            setAlbum(res.data);
            setLoading(false);

            if (requestedMediaItemIndex === -1) {
                const max_len = res.data.media_items.length - 1
                setSelectedMediaItemIndex(max_len);
                setSelectedMediaItem(res.data.media_items[max_len]);
            } else {
                setSelectedMediaItemIndex(requestedMediaItemIndex);
                setSelectedMediaItem(res.data.media_items[requestedMediaItemIndex]);
            }
        } catch (err) {
            setLoading(false);
            if (err.response) {
                if (err.response.status === 400) {
                    enqueueSnackbar("Er ging iets fout bij het ophalen van dit album.", {variant: "error"})
                }
            }
        }
    }

    const nextPage = () => {
        if (selectedPage < pages)
            setSelectedPage(selectedPage + 1);
    }

    const previousPage = () => {
        if (selectedPage > 1)
            setSelectedPage(selectedPage - 1);
    }

    const setPage = (i) => {
        if (i >= 1 && i <= pages) {
            setSelectedPage(i);
        }
    }

    const nextPhoto = () => {
        if (selectedMediaItemIndex < album.media_items.length-1) {
            setSelectedMediaItemIndex(selectedMediaItemIndex + 1)
        } else {
            if (selectedPage < pages) {
                setRequestedMediaItemIndex(0);
                setSelectedPage(selectedPage + 1);
            }
        }
    }

    const previousPhoto = () => {
        if (selectedMediaItemIndex > 0) {
            setSelectedMediaItemIndex(selectedMediaItemIndex - 1)
        } else {
            if (selectedPage > 1) {
                setRequestedMediaItemIndex(-1);
                setSelectedPage(selectedPage - 1);
            }
        }
    }

    const hasNextPhoto = useMemo(() => selectedMediaItemIndex < (album ? album.media_items.length-1 : 0) || selectedPage < pages, [selectedMediaItemIndex, album, selectedPage, pages]);
    const hasPreviousPhoto = useMemo(() => selectedMediaItemIndex > 0 || selectedPage > 1, [selectedMediaItemIndex, album, selectedPage, pages]);

    const openDialogWithPhoto = (i) => {
        setSelectedMediaItemIndex(i);
        setQueryParam('item', i);
        setPhotoViewerOpen(true);
    }

    const handlePhotoViewerClose = () => {
        setPhotoViewerOpen(false);
        removeQueryParam('item')
    }

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    }

    const openEditDialog = () => {
        setEditDialogOpen(true);
    }

    const closeEditDialog = () => {
        setEditDialogOpen(false);
    }

    const openDownloadDialog = () => {
        setDownloadDialogOpen(true);
    }

    const closeDownloadDialog = () => {
        setDownloadDialogOpen(false);
    }

    const setChildAlbum = (i, childAlbum) => {
        album.child_albums[i] = childAlbum;
        setAlbum({...album});
    }

    if (!album) {
        if (loading) {
            return (
                <div>
                    <Box mb={1}>
                        <Skeleton variant="rect" height={40} width={250}/>
                    </Box>
                    <Box mb={2}>
                        <Skeleton variant="rect" height={30} width={400}/>
                    </Box>
                    <Grid container spacing={1}>
                        {
                            [...Array(30)].map((e, i) => {
                                return (
                                    <Grid item xs={6} sm={4} md={2} lg={2} key={i}>
                                        <Skeleton variant="rect" height={140}/>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            )
        } else {
            return (
                <div>
                    <Typography variant="h5">
                        Ophalen van foto's mislukt.
                    </Typography>
                    <Typography variant="body1">
                        Controleer of je de juiste rechten hebt om dit album te mogen bekijken.
                    </Typography>
                </div>
            )
        }
    }

    return (
        <div>
            <Box display="flex" alignItems="flex-end">
                <Box mr={3}>
                    <Typography variant="h4" component="h4">
                        {album.title}
                    </Typography>
                </Box>

                <Box flexGrow={1}>
                    {album.media_items.length > 0 &&
                        <Typography variant="h6" color="textSecondary" component="h6">
                            {album.photo_amount} foto's
                        </Typography>
                    }
                </Box>

                {user.album_change_permission &&
                    <Tooltip title="Album aanpassen">
                        <IconButton onClick={openEditDialog}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                }
                {user.album_download_permission &&
                    <Tooltip title="Album downloaden">
                        <IconButton onClick={openDownloadDialog}>
                            <CloudDownload/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>

            {album.long_description ?
                <Box mt={2} mb={2}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}>
                            <Box display="flex" alignItems="center">
                                <Info className={classes.icons}/>
                                <Box ml={1}>
                                    <Typography className={classes.accordionHeading}>
                                        {album.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            {album.long_description.split('\\n').map((section, i) =>
                                <LinkText>
                                    <Typography key={i} component={"p"} gutterBottom>
                                        {section}
                                    </Typography>
                                </LinkText>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Box> :
                <Box mt={2} mb={2}>
                    <Typography className={classes.accordionHeading}>
                        {album.description}
                    </Typography>
                </Box>
            }
            {album.child_albums.length > 0 &&
                <Grid container spacing={1}>
                    {
                        album.child_albums.map((album, i) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={album.id}>
                                    <AlbumCard triggerReload={reloadAlbum} user={user} album={album} setAlbum={(childAlbum) => setChildAlbum(i, childAlbum)} className={classes.card} availableGroups={availableGroups} availableTags={availableTags}/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
            {(album.media_items.length === 0 && album.child_albums.length === 0) &&
                <Box mt={20} mb={20}>
                    <Box display="flex" justifyContent="center" mb={1}>
                        <BrokenImage/>
                    </Box>
                    <Typography variant="h2" component="h2" align="center">
                        Dit album heeft (nog) geen foto's.
                    </Typography>
                    {user.photo_add_permission &&
                        <Typography variant="h4" component="h4" align="center">
                            Klik op de knop onderin om ze toe te voegen.
                        </Typography>
                    }
                </Box>
            }
            <Grid container spacing={1}>
                {
                    album.media_items.map((photo, i) => {
                        return (
                            <Grid item xs={6} sm={4} md={2} lg={2} key={photo.id} onClick={() => openDialogWithPhoto(i)}>
                                <MediaCard mediaItem={photo}/>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <PageSelector nextPage={nextPage} previousPage={previousPage} setPage={setPage} selectedPage={selectedPage} pages={pages} pagesVisible={6}/>

            <AlbumSocialComponent user={user} setAlbum={setAlbum} album={album}/>

            {user.photo_add_permission &&
                <Fab className={classes.fab} onClick={() => setUploadDialogOpen(true)} color="secondary" aria-label="add">
                  <AddIcon />
                </Fab>
            }

            { selectedMediaItem &&
                <MediaContext.Provider value={{mediaItem: selectedMediaItem, setMediaItem: setSelectedMediaItem}}>
                    <MediaViewDialog user={user} album={album} setAlbum={setAlbum} previous={previousPhoto} next={nextPhoto} hasPrevious={hasPreviousPhoto} hasNext={hasNextPhoto} photo={selectedMediaItem} open={photoViewerOpen} handleClose={handlePhotoViewerClose} albumLoading={loading}/>
                </MediaContext.Provider>
            }

            <AlbumEditDialog user={user} album={album} reloadAlbum={reloadAlbum} open={editDialogOpen} onClose={closeEditDialog} availableGroups={availableGroups} availableTags={availableTags}/>
            <DownloadAlbumDialog user={user} album={album} open={downloadDialogOpen} onClose={closeDownloadDialog} autoDownload={params.get('download') !== null}/>
            <UploadDialog album={album} open={uploadDialogOpen} onClose={handleUploadDialogClose}/>
        </div>
    )
}

export default withRouter(AlbumPage);