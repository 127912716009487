import {AlbumsContext} from "./AlbumsContext";
import {SearchContext} from "./SearchContext";
import React, {useState} from "react";
import {SortTypes} from "../models/Types";
import {SortContext} from "./SortContext";
import SimpleDataProvider from "./SimpleDataProvider";

function StateProvider({children}) {
    const [albumSet, setAlbumSet] = useState({});
    const [search, setSearch] = useState("");
    const [sortType, setSortType] = useState(SortTypes.NEWEST_ASC);

    const [startSearch, setStartSearch] = useState(false);


    return (
        <SimpleDataProvider>
            <AlbumsContext.Provider value={{albumSet, setAlbumSet}}>
                <SortContext.Provider value={{sortType, setSortType}}>
                    <SearchContext.Provider value={{search, setSearch, startSearch, setStartSearch}}>
                        {children}
                    </SearchContext.Provider>
                </SortContext.Provider>
            </AlbumsContext.Provider>
        </SimpleDataProvider>
    )
}

export default StateProvider;