import React from "react";
import {Box} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIos, ArrowForwardIos, FirstPage, LastPage} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";

const style = (theme) => ({
    currentPage: {
        fontStyle: 'bold',
        color: theme.palette.primary.dark
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function PageSelector(props) {
    const classes = useStyles();

    const {nextPage, previousPage, setPage, selectedPage, pages, pagesVisible} = props;

    if (pages <= 1) {
        return null;
    }

    return (
        <Box display="flex" mt={4} justifyContent="center">
            <IconButton onClick={() => setPage(1)} disabled={selectedPage === 1}>
                <FirstPage/>
            </IconButton>
            <IconButton onClick={previousPage} disabled={selectedPage === 1}>
                <ArrowBackIos/>
            </IconButton>

            { pages <= pagesVisible ?
                [...Array(pages).keys()].map((key, i) => {
                    const p = key + 1
                    return (
                        <IconButton className={cx({[classes.currentPage]: selectedPage === p})} key={i} onClick={() => setPage(p)}>
                            {p}
                        </IconButton>
                    )
                }) :
                [...Array(pagesVisible).keys()].map((key, i) => {
                    let p = 1 + key;
                    if (selectedPage >= Math.ceil(pagesVisible / 2) + 1) {
                        p = key + selectedPage - Math.ceil(pagesVisible / 2);
                    }
                    if (p <= pages) {
                        return (
                            <IconButton className={cx({[classes.currentPage]: selectedPage === p})} key={i}
                                        onClick={() => setPage(p)}>
                                {p}
                            </IconButton>
                        )
                    } else {
                        return null
                    }
                })
            }

            <IconButton onClick={nextPage} disabled={selectedPage === pages}>
                <ArrowForwardIos/>
            </IconButton>
            <IconButton onClick={() => setPage(pages)} disabled={selectedPage === pages}>
                <LastPage/>
            </IconButton>
        </Box>
    )
}

export default PageSelector;