import React, {useContext, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Dialog, TextField, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {MediaContext} from "../Contexts/MediaContext";
import {MediaItem} from "../models/Types";
import axios from "axios";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {FileCopy} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    hardlinkBox: {
        fontFamily: "Consolas",
        border: "1px solid",
        borderRadius: "5px",
        padding: "5px",
        borderColor: theme.palette.grey.A200,
        color: theme.palette.grey.A400,
        backgroundColor: "#dedede",
        marginBottom: "2px",

    },
    copy: {
        fontSize: '0.7rem',
        color: theme.palette.text.hint,
        cursor: "pointer",

        '&:hover': {
            color: theme.palette.text.primary
        }
    },
    copyIcon: {
        fontSize: '0.7rem'
    }
}));

function ItemHardlinkDialog(props) {
    const {open, onClose} = props;

    const { enqueueSnackbar } = useSnackbar();
    const {mediaItem} = useContext(MediaContext);

    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [hardlink, setHardlink] = useState(null);

    const getHardlink = () => {
        axios.get(`/api/media/${mediaItem.id}/hardlink/`).then((res) => {
            if (res.data.success) {
                setHardlink(res.data.url);
            }
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
            onClose();
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (open) {
            getHardlink();
        }
    }, [open]);

    const copyClipboard = () => {
        navigator.clipboard.writeText(hardlink);
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                {loading ? <Box display="flex" justifyContent="center"><CircularProgress color="inherit" /></Box> :
                    <div>
                        <div className={classes.hardlinkBox}>
                            <Typography component="p" variant="p">
                                {hardlink}
                            </Typography>
                        </div>
                        <Box display="flex" alignItems="center" className={classes.copy}>
                            <FileCopy className={classes.copyIcon} />

                            <Typography component="p" variant="p" onClick={copyClipboard}>
                                Kopiëren naar klembord
                            </Typography>
                        </Box>
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Afsluiten
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ItemHardlinkDialog;