import React, {useEffect, useMemo, useRef, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {useSnackbar} from "notistack";
import axios from "axios";
import {createFilterOptions} from "@material-ui/lab";

const filter = createFilterOptions();

function PhotoTag(props) {
    const {user, photo} = props;
    const { enqueueSnackbar } = useSnackbar();

    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reloadTags, setReloadTags] = useState(true);

    const nonPickedTags = useMemo(() => tags.filter((tag) => selectedTags.map((tag) => tag.name).indexOf(tag.name) === -1), [tags, selectedTags]);

    const textBox = useRef(null);

    const stopPropagation = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        textBox.current?.addEventListener('keydown', stopPropagation);
        return () => textBox.current?.removeEventListener('keydown', stopPropagation);
    }, [textBox]);

    useEffect(() => {
        setSelectedTags(photo.tags);
    }, [photo])

    useEffect(() => {
        if (reloadTags) {
            setLoading(true);
            axios.get('/api/media/tags/').then((res) => {
                setTags(res.data.map((tag) => ({showValue: tag.name, name: tag.name})));
                setLoading(false);
                setReloadTags(false);
            });
        }
    }, [reloadTags]);

    const changeTags = (e, newValue) => {
        let values = [];

        newValue.map((value) => {
            if (typeof value === "string") {
                values.push({
                    name: value,
                    showValue: value
                })
            } else {
                values.push(value);
            }
        })

        photo.tags = values;
        setSelectedTags(values);

        axios.post(`/api/media/${photo.id}/mediatag/`, {tags: values.map((value) => value.name)}).then((res) => {
            setReloadTags(true);
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        })
    }
    return (
        <div>
            {user.mediatag_change_permission ?
                <Autocomplete
                    multiple
                    limitTags={2}
                    open={autocompleteOpen}
                    onOpen={() => setAutocompleteOpen(true)}
                    onClose={() => setAutocompleteOpen(false)}
                    options={nonPickedTags}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                name: params.inputValue,
                                showValue: `"${params.inputValue}" toevoegen`,
                            });
                        }

                        return filtered;
                    }}
                    loading={loading}
                    value={selectedTags}
                    renderOption={(option) => option.showValue}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }

                        // Add "xxx" option created dynamically
                        if (option.name) {
                            return option.name;
                        }
                        // Regular option
                        return option.showValue;
                    }}
                    freeSolo
                    onChange={changeTags}
                    renderInput={(params) => <TextField {...params} ref={textBox} label="Tags toevoegen" variant="outlined" placeholder="Tag toevoegen"/>}
                /> :
                selectedTags.length > 0 &&
                    <div>
                        <Typography variant="caption" component="p">Foto tags:</Typography>
                        {selectedTags.map((tag, i) => {
                            return <Chip key={i} size="small" label={tag.name}/>
                        })}
                    </div>
            }
        </div>
    )
}

export default PhotoTag;