import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";
import axios from "axios";

function DeletePhotoDialog(props) {

    const { enqueueSnackbar } = useSnackbar();
    const {photo, open, onClose, onDeletePhoto} = props;

    const deletePhoto = () => {
        return axios.delete(`/api/media/${photo.id}/`).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: "success"});
            onDeletePhoto();
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        }).finally(() => {
            onClose();
        })
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Foto verwijderen?
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="p">
                    Weet je zeker dat je {photo.title} wilt verwijderen?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={deletePhoto}>
                    Ja
                </Button>
                <Button onClick={onClose}>
                    Nee
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeletePhotoDialog;