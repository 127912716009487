import React from 'react';
import Linkify from "react-linkify";

const LinkText = ({children}) => {
    return (
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
            </a>
        )}>
            {children}
        </Linkify>
    );
};

export default LinkText;