var md5 = require('md5');
var dayjs = require('dayjs');
var uploadFinishedDate = dayjs("2021-03-15");
var ogName = "Scorpios";
// MD5 Hashes of initial fotoplatform uploaders to show them as "Scorpios" without saving their real names here
export var InitialUploaders = [
    "39dcf95a61d1b94b8967265b91798399",
    "e0f2403d3d948eb88cac2bce9d49cb7c",
    "fce652542a6290cdd16d77dcc8483972",
    "fd07f531b4ed0e5e66becabe8b9daf8c",
    "6fea730611e62357f741b467a0c9ae3a",
    "7b028c7c261fbc4efb16c497d1fc8c50",
    "51f7c816251fc65f1453fc49240a00d6",
    "9f876e6f0e0b8ffa2c0f96e66cb83b77",
    "ef4d1986d25dcb1c476623ab0f15ad7e",
    "c5bbb464dd4a0df45f87ac4ee777aaf5",
    "79d690449f9cbb651767f82673c531cb",
    "699e3424ae4807a5072cf29533d543e1",
    "7f303a03a1c95942a64678e293e75a7f",
    "539f3547220e21c345daad0078783559",
    "0dc17fadac01fe29ff2d470a469905c2",
    "828ef61c20b478b6efd8e92c35c46337",
    "b60a897e10a61758c8db9dd8cb72fd07",
    "7919fd356ff37240db10521ff22dd72c",
    "acc3d8090c6c654fd5e8c26e411f69e5",
    "a40a0916d2ceaa87bbe982f038a86475",
    "a30119bf40500011796d86cc5583aae5"
];
var HashCache = {};
export var getInitialUploader = function (name) {
    var hash = "";
    if (name in HashCache) {
        hash = HashCache[name];
    }
    else {
        hash = md5(name);
        HashCache[name] = hash;
    }
    if (InitialUploaders.indexOf(hash) > -1 && dayjs() <= uploadFinishedDate) {
        return ogName;
    }
    else {
        return name;
    }
};
