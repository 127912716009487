import {Clear, Search} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import {SearchContext} from "../Contexts/SearchContext";
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    searchField: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: "45vw"
        }
    },
    searchIcon: {
        marginRight: theme.spacing(1)
    }
}));

function AlbumSearchComponent() {
    const classes = useStyles();

    const {search, setSearch, setStartSearch} = useContext(SearchContext);

    const changeSearchInput = (e) => {
        setSearch(e.target.value);
        setStartSearch(true);
    }

    const clearInput = () => {
        setSearch("");
        setStartSearch(true);
    }

    return (
        <Box display="flex" alignItems="center">
            <Search className={classes.searchIcon} />
            <TextField label="Zoeken" value={search} className={classes.searchField} size="small" variant="outlined" onChange={changeSearchInput} />
            <Box ml={1}>
                <IconButton size="small" onClick={clearInput}>
                    <Clear fontSize="small"/>
                </IconButton>
            </Box>
        </Box>
    )
}

export default AlbumSearchComponent;