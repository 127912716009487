import {useSnackbar} from "notistack";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import axios from "axios";
import {Box, Button, ButtonGroup, Card} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {Message} from "@material-ui/icons";
import ThumbUp from "@material-ui/icons/ThumbUp";
import {makeStyles} from "@material-ui/styles";
import classNames from "classnames";
import AlbumComment from "./AlbumComment";
import LikeComponent from "./LikeComponent";

const style = (theme) => ({
    section: {
        height: '100%'
    },
    comments: {
        height: '80%',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.text.secondary,
        borderRadius: '4px',
        overflowY: 'scroll'
    },
    textField: {
        width: '100%'
    },
    liked: {
        color: theme.palette.success.main,
        padding: '15px'
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '15px'
    }
});

const useStyles = makeStyles(style);
const cx = classNames.bind(style);

function AlbumSocialComponent(props) {
    const {user, album, setAlbum} = props;

    const classes = useStyles();

    const [likes, setLikes] = useState([]);
    const [albumLiked, setAlbumLiked] = useState(false);

    const [commentText, setCommentText] = useState("");
    const [uploadingComment, setUploadingComment] = useState(false);

    const scrollDiv = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const deleteComment = (comment) => {
        axios.delete(`/api/album/${album.id}/comments/${comment.id}/`).then((res) => {
            enqueueSnackbar(res.data.msg, {variant: 'success'})
            album.comments.map((search, i) => search.id === comment.id && album.comments.splice(i, 1))
            setAlbum({...album});
        }).catch((error) => {
            if (error.response) {
                enqueueSnackbar(error.response.data.msg, {variant: "error"});
            }
        });
    };

    useLayoutEffect(() => {
        scrollToBottom();
    }, [scrollDiv, album]);

    const scrollToBottom = () => {
        scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight;
    }

    useEffect(() => {
        setLikes(album.likes);

        if (album.likes.map((like) => like.id).includes(user.id)) {
            setAlbumLiked(true);
        } else {
            setAlbumLiked(false);
        }
    }, [album]);

    const likeAlbum = () => {
        setAlbumLiked(!albumLiked);

        if (!albumLiked) {
            axios.post(`/api/album/${album.id}/like/`).then((res) => {
                album.likes.push(user)
                setLikes(album.likes.slice())
            });
        } else {
            axios.delete(`/api/album/${album.id}/like/`).then((res) => {
                album.likes.map((like) => like.id === user.id && album.likes.pop(like));
                setLikes(album.likes.slice())
            });
        }
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            putComment()
        }
    }

    const putComment = () => {
        if (!uploadingComment) {
            setUploadingComment(true);

            const data = {
                text: commentText
            }

            axios.put(`/api/album/${album.id}/comments/`, data).then((res) => {
                enqueueSnackbar(res.data.msg, {variant: "success"});
                setCommentText("")
                album.comments.push(res.data.comment);
                setTimeout(scrollToBottom, 100);
            }).catch((error) => {
                if (error.response) {
                    enqueueSnackbar(error.response.data.msg, {variant: "error"});
                }
            }).finally(() => {
                setUploadingComment(false);
            })
        }
    }

    const buttonClasses = cx({
        [classes.liked]: albumLiked,
        [classes.button]: !albumLiked
    });

    return (
        <Box mt={2} mb={4}>
            <LikeComponent prefix="Album" likes={likes}/>
            <Box mt={4} mb={2} display="flex" alignItems="center">
                <Box flexGrow={1} mr={1}>
                    <TextField disabled={uploadingComment} value={commentText} inputProps={{ maxLength: 4096 }} onKeyPress={onKeyPress} onChange={(e) => setCommentText(e.target.value)} className={classes.textField} variant="outlined" label="Comment plaatsen"/>
                </Box>
                <ButtonGroup>
                    <Button onClick={putComment} className={classes.button}>
                        <Message/>
                    </Button>
                    <Button onClick={likeAlbum} className={buttonClasses}>
                        <ThumbUp/>
                    </Button>
                </ButtonGroup>

            </Box>
            <Box ref={scrollDiv}>
                {album.comments.map((comment) => {
                    // Math.random() because we want the comment to redraw each time
                    return <AlbumComment key={Math.random()} album={album} comment={comment} user={user} onDelete={deleteComment}/>
                })}
            </Box>
        </Box>
    )
}

export default AlbumSocialComponent;